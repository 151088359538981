import React from "react";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useDarkTheme from "../../hooks/useDarkTheme";

import SmallServiceOfferCard from "../card/dashboard/SmallServiceOfferCard";

const SmallServiceOffersList = ({ tender, serviceOffers, refreshServiceOffers, isLastBatch, isLoading, endMessageShown }) => {
  ring2.register();

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  return serviceOffers.length !== 0 ? (
    <>
      <div className="flex flex-col gap-4">
        {serviceOffers.map((offer, i) => {
          return <SmallServiceOfferCard key={"serviceOffer-" + i} tender={tender} offer={offer} index={i} refreshServiceOffers={refreshServiceOffers} />;
        })}
      </div>
      {!isLastBatch && (
        <div className={`flex items-center justify-center pt-4 pb-8 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
          <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
        </div>
      )}
      {isLastBatch && endMessageShown && (
        <div className={`flex items-center py-4 text-center ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
          <p>{t("reachedServiceOffersEnd")}</p>
        </div>
      )}
    </>
  ) : (
    <div className="flex items-center justify-center border-2 border-dashed border-neutral-400 w-full min-h-40 shadow-md rounded-lg text-center">{t("noServiceOffers")}</div>
  );
};

export default SmallServiceOffersList;
