import React, { useRef } from "react";
import { IconContext } from "react-icons";

import Avatar from "../../avatar/Avatar";
import ProfilePopup from "../profilePopup/ProfilePopup";

const MobileSidebarButton = ({ type, link, action, image, subimage, alt, subalt, icon, popupOpen, togglePopup }) => {
  const buttonRef = useRef(null);

  const contents = (
    <>
      {type === "avatar" && (
        <Avatar
          image={image}
          alt={alt}
          subimage={subimage}
          subalt={subalt}
          className={"!w-9 !h-9"}
          mobilePopup={<ProfilePopup popupOpen={popupOpen} togglePopup={togglePopup} buttonRef={buttonRef} modifiers="mobile" />}
        />
      )}
      {image && type !== "avatar" && <img src={image} alt={alt} className="h-full m-auto" />}
      {icon && type !== "center" && <IconContext.Provider value={{ className: "w-full h-full" }}>{icon}</IconContext.Provider>}
      {icon && type === "center" && (
        <IconContext.Provider value={{ className: "absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/4 w-14 h-14" }}>{icon}</IconContext.Provider>
      )}
    </>
  );

  return (
    <div ref={buttonRef} className="relative flex-1 h-10">
      {link && (
        <a href={link} className="flex items-center justify-center w-full h-full">
          {contents}
        </a>
      )}
      {action && (
        <div onClick={action} className="flex items-center justify-center w-full h-full cursor-pointer">
          {contents}
        </div>
      )}
    </div>
  );
};

export default MobileSidebarButton;
