import { useContext } from "react";

import { DimensionsContext } from "../../contexts/DimensionsContext";

const useSettingsSectionJSModifiers = () => {
  const dimensionsContext = useContext(DimensionsContext);

  const userNotificationsLogin = () => {
    return dimensionsContext.isMobile ? "" : "text-at-bottom";
  };

  const companyNotificationsApproved = () => {
    return dimensionsContext.isMobile ? "" : "text-at-bottom";
  };

  return {
    userNotificationsLogin,
    companyNotificationsApproved
  };
};

export default useSettingsSectionJSModifiers;
