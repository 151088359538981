import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import useImagePreloader from "../../hooks/useImagePreloader";
import useAuth from "../../hooks/useAuth";
import { SelectedCompanyContext } from "../../contexts/SelectedCompanyContext";

import {
  MdChevronLeft,
  MdChevronRight,
  MdAddBox,
  // MdBarChart,
  MdMarkChatUnread,
  // MdNotifications,
  // MdNotificationsActive,
  // MdOutlineViewKanban,
  MdOutlineReadMore,
  MdOutlineQuestionAnswer,
  MdManageSearch,
  MdOutlineContentPasteGo,
  // MdOutlineChecklist,
  MdOutlineDesignServices,
  MdSettings,
  MdHome,
  MdSecurity
} from "react-icons/md";
import Logo from "../../images/logo/logo-dark_theme.svg";

import SidebarButton from "./SidebarButton";
// import Separator from "../Separator";
import SubscribeBanner from "./SubscribeBanner";
import Separator from "../Separator";

const preloadSrcList = [Logo];
const DashboardSidebar = ({ setSidebarExpanded, sidebarExpanded }) => {
  const { t } = useTranslation();

  useImagePreloader(preloadSrcList);

  const { fullName, isCompany, isAdmin, hasActiveSub, profilePic } = useAuth();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <div className="fixed flex bg-neutral-800 dark:bg-neutral-950 text-white h-full z-20">
      <button
        onClick={() => {
          setSidebarExpanded(!sidebarExpanded);
        }}
        className="absolute flex justify-center bg-neutral-650 dark:bg-neutral-750 top-4 left-0 w-7 h-7 ml-[100%] -translate-x-1/2 p-1 rounded-full shadow-2xl"
      >
        <IconContext.Provider value={{ className: "w-full h-full" }}>{sidebarExpanded ? <MdChevronLeft /> : <MdChevronRight />}</IconContext.Provider>
      </button>
      <div className="flex flex-col w-16 h-full p-4 space-y-4">
        <SidebarButton type="logo" link="/dashboard" image={Logo} alt="Hoptop Logo" label="Hoptop" sidebarExpanded={sidebarExpanded} setSidebarExpanded={setSidebarExpanded} />
        <div className="flex-1 flex flex-col space-y-3">
          {!isCompany() && (
            <>
              <SidebarButton link="/dashboard/tenders/create" icon={<MdAddBox />} label={t("dashboardCreateTender")} sidebarExpanded={sidebarExpanded} />
              <SidebarButton link="/dashboard/tenders" icon={<MdOutlineReadMore />} label={t("dashboardMyTenders")} sidebarExpanded={sidebarExpanded} />
            </>
          )}
          {isCompany() && (
            <>
              <SidebarButton link="/dashboard/tenders/browse" icon={<MdManageSearch />} label={t("dashboardAllTenders")} sidebarExpanded={sidebarExpanded} />
              <SidebarButton link={`/dashboard/service-offers`} icon={<MdOutlineContentPasteGo />} label={t("dashboardMyServiceOffers")} sidebarExpanded={sidebarExpanded} />
              <SidebarButton link={`/dashboard/projects`} icon={<MdOutlineDesignServices />} label={t("dashboardProjects")} sidebarExpanded={sidebarExpanded} />

              <Separator className="dark:border-neutral-200" />
              {/*  <SidebarButton link={`/dashboard/kanban`} icon={<MdOutlineViewKanban />} label={t("dashboardTodo")} sidebarExpanded={sidebarExpanded} /> */}
              {/*
              <SidebarButton
                link={`/dashboard/stats`}
                icon={<MdBarChart />}
                label={t("dashboardStatistics")}
                sidebarExpanded={sidebarExpanded}
              />
              */}
            </>
          )}
          <SidebarButton
            link="/dashboard/messages"
            icon={"TODO" !== "implement chat read/unread" ? <MdOutlineQuestionAnswer /> : <MdMarkChatUnread />}
            label={t("dashboardMessages")}
            sidebarExpanded={sidebarExpanded}
          />
        </div>
        <div className="flex flex-col space-y-3">
          {/*
          <SidebarButton
            link="/dashboard/notifications"
            icon={"TODO" !== "implement notifications read/unread" ? <MdNotifications /> : <MdNotificationsActive />}
            label={t("dashboardNotifications")}
            sidebarExpanded={sidebarExpanded}
        />
        */}
          {isAdmin() && <SidebarButton link="/dashboard/admin" icon={<MdSecurity />} label={t("dashboardAdmin")} sidebarExpanded={sidebarExpanded} />}
          <SidebarButton link="/dashboard/settings" icon={<MdSettings />} label={t("dashboardSettings")} sidebarExpanded={sidebarExpanded} />
          <SidebarButton link="/" icon={<MdHome />} label={t("dashboardBackHome")} sidebarExpanded={sidebarExpanded} />
          {!hasActiveSub() && <SubscribeBanner sidebarExpanded={sidebarExpanded} />}
          <SidebarButton
            type="avatar"
            action={togglePopup}
            image={profilePic()}
            alt={`Profile picture for ${fullName()}`}
            subimage={!selectedCompany.isNull ? selectedCompany.logo : undefined}
            subalt={!selectedCompany.isNull ? `Logo for ${selectedCompany.name}` : undefined}
            label={fullName()}
            sublabel={!selectedCompany.isNull ? selectedCompany.name : undefined}
            sidebarExpanded={sidebarExpanded}
            popupOpen={popupOpen}
            togglePopup={togglePopup}
          />
        </div>
      </div>
      <div className={`w-0 pointer-events-none transition-all ${sidebarExpanded && "!w-56"}`} />
    </div>
  );
};

export default DashboardSidebar;
