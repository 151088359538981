import React from "react";

import HomeStart from "./HomeStart";
import Fonctionnalite from "./FonctionnaliteEntreprises";
import Faq from "./FaqBusinesses";
// import Team from "./Team";
//import Blogue from "./Blogue";
import Services from "./ServicesFastEntreprises";
import Price from "./Price";

const HomeBusinesses = () => {
  return (
    <div>
      <HomeStart page="business" />
      <div className="bg-neutral-100 dark:bg-neutral-800">
        <section id="services">
          <Services />
        </section>
        <section id="resources">
          <Fonctionnalite />
        </section>
        <section id="pricing">
          <Price />
        </section>
        {/*<section id="team">
          <Team />
        </section>*/}
        <section id="faq">
          <Faq />
        </section>
      </div>
    </div>
  );
};

export default HomeBusinesses;
