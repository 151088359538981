import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import CarouselButton from "../carousel/elements/CarouselButton";
import CarouselIndicator from "../carousel/elements/CarouselIndicator";
import TenderCardHome from "../card/dashboard/TenderCardHome";

const TendersCarouselHome = ({ type = "tenders", tenders = [] }) => {
  const { t } = useTranslation();

  const [shownIndex, setShownIndex] = useState(0);

  return (
    <div className="relative">
      {tenders === null ? (
        <p>{t("fetchingTenders")}</p>
      ) : tenders.length > 0 ? (
        <div>
          <CarouselButton side="left" length={tenders.length} shownIndex={shownIndex} setShownIndex={setShownIndex} />
          <div className="relative flex overflow-hidden pb-5">
            {tenders.map((tender) => {
              return (
                <div key={tender._id} className={`w-full min-h-[calc(100%-1px+1px)] px-10 transition-transform duration-300 -translate-x-[${shownIndex}00%]`}>
                  <TenderCardHome
                    id={tender._id}
                    addr={tender.addr}
                    createdAt={tender.createdAt}
                    deliverBy={tender.deliverBy}
                    description={tender.description}
                    city={tender.city}
                    subdomain={tender.subdomain}
                    urgent={tender.urgent}
                    status={tender.status}
                    title={tender.title}
                    user={tender.author}
                    loggedUserIsAuthor={tender.loggedUserIsAuthor}
                    modifiers="mobile"
                  />
                </div>
              );
            })}
            <CarouselIndicator length={tenders.length} shownIndex={shownIndex} setShownIndex={setShownIndex} />
          </div>
          <CarouselButton side="right" length={tenders.length} shownIndex={shownIndex} setShownIndex={setShownIndex} />
        </div>
      ) : (
        <p className="text-left">{type === "tenders" ? t("noTenders") : type === "myTenders" ? t("noMyTenders") : type === "myProjects" && t("noMyProjects")}</p>
      )}
      {/* XXX Preloaded Tailwind classes, do NOT remove */}
      <div className="hidden -translate-x-[000%]" />
      <div className="hidden -translate-x-[100%]" />
      <div className="hidden -translate-x-[200%]" />
    </div>
  );
};

export default TendersCarouselHome;
