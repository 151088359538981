import useGeneral from "./useGeneral";
import useJSItems from "../data/hooks/useJSItems";
import useJSLabels from "../data/hooks/useJSLabels";
import useJSLinks from "../data/hooks/useJSLinks";
import useJSOnChange from "../data/hooks/useJSOnChange";
import useSettingsJSDirections from "../data/hooks/useSettingsJSDirections";
import useSettingsJSModifiers from "../data/hooks/useSettingsJSModifiers";
import useSettingsVisibility from "../data/hooks/useSettingsVisibility";

import Button from "../components/button/Button";
import Checkbox from "../components/forms/inputs/Checkbox";
import DropdownInput from "../components/forms/inputs/DropdownInput";
import FormMap from "../components/map/FormMap";
import Input from "../components/forms/inputs/Input";
import InputGroup from "../components/forms/inputs/InputGroup";
import Toggle from "../components/forms/inputs/Toggle";

const useSettings = () => {
  const TYPE_TOGGLE = "toggle",
    TYPE_TEXT = "text",
    TYPE_CHECKBOX = "checkbox",
    TYPE_INPUT = "input",
    TYPE_DROPDOWN = "dropdown",
    TYPE_GROUP = "inputgroup",
    TYPE_ADDRESS = "address",
    TYPE_BUTTON = "button";

  const { localeLabelToString, actionStringToFunction } = useGeneral();

  const JS_ITEMS = useJSItems();
  const JS_LABELS = useJSLabels();
  const JS_LINKS = useJSLinks();
  const JS_ON_CHANGE = useJSOnChange();
  const SETTINGS_JS_DIRECTIONS = useSettingsJSDirections();
  const SETTINGS_JS_MODIFIERS = useSettingsJSModifiers();
  const SETTINGS_VISIBILITY = useSettingsVisibility();

  const settingByType = (setting, formData, setFormData) => {
    const isVisible = SETTINGS_VISIBILITY[setting.id];

    if (setting?.label && typeof setting?.label === "string") {
      setting.label = localeLabelToString(setting.label);
    }

    if (setting?.action && typeof setting?.action === "string") {
      setting.action = actionStringToFunction(setting.action);
    }

    if (setting?.onChange && typeof setting?.onChange === "string") {
      setting.onChange = JS_ON_CHANGE[setting.onChange];
    }

    if (setting?.jsLink) {
      setting.link = JS_LINKS[setting.jsLink]();
    }

    if (setting?.jsLabel) {
      setting.label = JS_LABELS[setting.jsLabel]();
    }

    if (setting?.jsItems) {
      setting.items = JS_ITEMS[setting.jsItems]();
    }

    if (setting?.jsDirection) {
      setting.direction = SETTINGS_JS_DIRECTIONS[setting.jsDirection]();
    }

    if (setting?.jsModifiers) {
      setting.modifiers = SETTINGS_JS_MODIFIERS[setting.jsModifiers]();
    }

    if (isVisible === undefined || isVisible()) {
      switch (setting.type) {
        default:
        case TYPE_TOGGLE:
          return (
            <Toggle
              key={setting.id}
              formData={formData}
              setFormData={setFormData}
              from={setting.from}
              id={setting.id}
              name={setting.id}
              label={setting.label}
              required={setting.required}
              disabled={false}
            />
          );

        case TYPE_TEXT:
          return typeof setting.label === "string" ? (
            <p key={setting.id} className={setting.className}>
              {setting.label}
            </p>
          ) : (
            <div key={setting.id} className={setting.className}>
              {setting.label}
            </div>
          );

        case TYPE_CHECKBOX:
          return (
            <Checkbox
              key={setting.id}
              formData={formData}
              setFormData={setFormData}
              from={setting.from}
              id={setting.id}
              name={setting.id}
              label={setting.label}
              required={setting.required}
              disabled={setting.disabled}
            />
          );

        case TYPE_INPUT:
          return (
            <Input
              key={setting.id}
              type={setting.inputType}
              formData={formData}
              setFormData={setFormData}
              id={setting.id}
              label={setting.label}
              required={setting.required}
              disabled={setting.disabled}
              modifiers={setting.modifiers}
            />
          );

        case TYPE_DROPDOWN:
          return (
            <DropdownInput
              key={setting.id}
              formData={formData}
              setFormData={setFormData}
              id={setting.id}
              label={setting.label}
              items={setting.items}
              hasNone={setting.hasNone}
              onChange={setting.onChange}
              required={setting.required}
              disabled={setting.disabled}
            />
          );

        case TYPE_GROUP:
          return (
            <InputGroup key={setting.id} direction={setting.direction} items={setting.items} modifiers={`settings ${setting.modifiers}`}>
              {setting.items.map((item) => {
                return settingByType(item, formData, setFormData);
              })}
            </InputGroup>
          );

        case TYPE_ADDRESS:
          return (
            <FormMap
              key={setting.id}
              id={setting.id}
              type={setting.mapType}
              formData={formData}
              setFormData={setFormData}
              hasHome={setting.hasHome}
              modifiers={setting.modifiers}
              setShowingMap={setting.setShowingMap}
            />
          );

        case TYPE_BUTTON:
          return <Button key={setting.id} link={setting.link} action={setting.action} label={setting.label} disabled={setting.disabled} modifiers="width-md no-margin" />;
      }
    }

    return null;
  };

  return { settingByType };
};

export default useSettings;
