import React from "react";
import { useTranslation } from "react-i18next";

const SupportedSubdomain = ({ subdomain, services }) => {
  const { t } = useTranslation();

  return (
    <p>
      — {t(`tenderSubdomains.${subdomain}`)}:{" "}
      {services.map((service, i) => {
        return `${services.length !== 1 && i === services.length - 1 ? "& " : ""}${t(`tenderServices.${service.name}`)}${i !== services.length - 1 ? ", " : ""}`;
      })}
    </p>
  );
};

export default SupportedSubdomain;
