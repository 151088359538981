/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import useSettings from "../../../hooks/useSettings";
import { SettingsFormDataContext } from "../../../data/contexts/SettingsFormDataContext";

import useSettingsSectionJSDirections from "../../../data/hooks/useSettingsSectionJSDirections";
import useSettingsSectionJSModifiers from "../../../data/hooks/useSettingsSectionJSModifiers";
import useSettingsSectionVisibility from "../../../data/hooks/useSettingsSectionVisibility";
import { DimensionsContext } from "../../../contexts/DimensionsContext";

const Section = ({ section, formData, setFormData }) => {
  const { t } = useTranslation();

  const { settingByType } = useSettings();
  const dimensionsContext = useContext(DimensionsContext);
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const SETTINGS_SECTION_JS_DIRECTIONS = useSettingsSectionJSDirections();
  const SETTINGS_SECTION_JS_MODIFIERS = useSettingsSectionJSModifiers();
  const SETTINGS_SECTION_VISIBILITY = useSettingsSectionVisibility();
  const isVisible = SETTINGS_SECTION_VISIBILITY[section.id];

  useEffect(() => {
    if (section?.jsDirection) {
      section.direction = SETTINGS_SECTION_JS_DIRECTIONS[section.jsDirection]();
    }

    if (section?.jsModifiers) {
      section.modifiers = SETTINGS_SECTION_JS_MODIFIERS[section.jsModifiers]();
    }
  }, [settingsFormDataContext?.formData, dimensionsContext?.isMobile]);

  if (isVisible === undefined || isVisible()) {
    return (
      <div
        className={`flex min-h-10 ${
          section.direction === "vertical" ? "flex-col space-y-4" : section.direction === "horizontal" && "flex-row items-center justify-between space-x-6"
        } ${section.modifiers?.includes("text-at-bottom") && "!items-end"}`}
      >
        {!section.noTitle || !section.noDesc ? (
          <div className="w-fit">
            {!section.noTitle && <p className="font-semibold">{t(`settings.sections.${section.id}.title`)}</p>}
            {!section.noDesc && <p className="text-xs">{t(`settings.sections.${section.id}.desc`)}</p>}
          </div>
        ) : null}
        <div className={`flex-1 ${section.direction === "vertical" ? "!space-y-4" : section.direction === "horizontal" && "flex justify-end space-x-4"}`}>
          {section.settings.map((setting) => {
            return settingByType(setting, formData, setFormData);
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default Section;
