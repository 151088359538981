import React, { useEffect, useState } from "react";
import { APIProvider } from "@vis.gl/react-google-maps";

import ShowMap from "./ShowMap";
import CoordsLoader from "./components/CoordsLoader";

const RegularMap = ({ type = "marker", address, coords: propsCoords, modifiers = "" }) => {
  const [coords, setCoords] = useState();

  useEffect(() => {
    if (propsCoords?.lat && propsCoords?.lng) {
      setCoords(propsCoords);
    }
  }, [propsCoords]);

  return (
    <div className="flex">
      <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        {address && !propsCoords && <CoordsLoader address={address} setCoords={setCoords} />}
        <ShowMap type={type} coords={coords} modifiers={modifiers} />
      </APIProvider>
    </div>
  );
};

export default RegularMap;
