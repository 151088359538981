import { useContext } from "react";

import useAuth from "../../hooks/useAuth";
import useStripe from "../../hooks/useStripe";
import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

const useSettingsSectionVisibility = () => {
  // eslint-disable-next-line no-unused-vars
  const { companies, hasNoSub, hasPendingSub, hasActiveSub } = useAuth();
  const { getSubscription } = useStripe();
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const companiesPreferred = () => {
    return companies();
  };
  const companiesSelected = () => {
    return companies();
  };

  const companiesInfo = () => {
    return companies() && settingsFormDataContext.formData.companiesSelectedDropdown;
  };

  const companiesAddress = () => {
    return companies() && settingsFormDataContext.formData.companiesSelectedDropdown;
  };

  const companiesSubdomains = () => {
    return companies() && settingsFormDataContext.formData.companiesSelectedDropdown && settingsFormDataContext.formData.companiesSubdomainsCheckboxes;
  };

  const companiesDelete = () => {
    return companies() && settingsFormDataContext.formData.companiesSelectedDropdown;
  };

  const companiesNoSub = () => {
    return hasNoSub();
  };

  const companiesUnconfirmedSub = () => {
    return hasPendingSub();
  };

  const companiesAdd = () => {
    return hasActiveSub() && !companies();
  };

  const subscriptionCurrent = () => {
    return hasActiveSub() && getSubscription();
  };

  const subscriptionStatus = () => {
    return hasActiveSub() && getSubscription();
  };

  const subscriptionStartedOn = () => {
    return hasActiveSub() && getSubscription() && getSubscription().status === "active";
  };

  const subscriptionRenewsOn = () => {
    return hasActiveSub() && getSubscription() && getSubscription().status === "active" && !getSubscription().cancelled;
  };

  const subscriptionEndsOn = () => {
    return hasActiveSub() && getSubscription() && getSubscription().status === "active" && getSubscription().cancelled;
  };

  const subscriptionPricingPage = () => {
    return hasNoSub();
  };

  const subscriptionAccessLink = () => {
    return hasPendingSub() && getSubscription() && !getSubscription().paymentExpired;
  };

  const subscriptionRenewLink = () => {
    return hasPendingSub() && getSubscription() && getSubscription().paymentExpired;
  };

  const subscriptionChangeLink = () => {
    return hasPendingSub() && getSubscription();
  };

  const subscriptionCancel = () => {
    return hasActiveSub() && getSubscription() && !getSubscription().cancelled;
  };

  const subscriptionRestart = () => {
    return hasActiveSub() && getSubscription() && getSubscription().status === "cancelled";
  };

  const subscriptionStartTier = () => {
    return hasNoSub() || hasPendingSub();
  };

  const subscriptionStartSelect = () => {
    return hasNoSub() || hasPendingSub();
  };

  const subscriptionStartBeginProcess = () => {
    return hasNoSub() || hasPendingSub();
  };

  const companyNotificationsSelected = () => {
    return companies();
  };

  const companyNotificationsApproved = () => {
    return companies() && settingsFormDataContext.formData.companyNotificationsSelectedDropdown;
  };

  const companyNotificationsTender = () => {
    return companies() && settingsFormDataContext.formData.companyNotificationsSelectedDropdown;
  };

  const companyNotificationsReview = () => {
    return companies() && settingsFormDataContext.formData.companyNotificationsSelectedDropdown;
  };

  const companyNotificationsConversation = () => {
    return companies() && settingsFormDataContext.formData.companyNotificationsSelectedDropdown;
  };

  const companyNotificationsMessage = () => {
    return companies() && settingsFormDataContext.formData.companyNotificationsSelectedDropdown;
  };

  const companyNotificationsNoSub = () => {
    return hasNoSub();
  };

  const companyNotificationsUnconfirmedSub = () => {
    return hasPendingSub();
  };

  const companyNotificationsAdd = () => {
    return hasActiveSub() && !companies();
  };

  const mfaPreferred = () => {
    return settingsFormDataContext.formData.mfaAuthenticatorIsSetup || settingsFormDataContext.formData.mfaEmailIsSetup || settingsFormDataContext.formData.mfaSMSIsSetup;
  };

  // XXX TEMPORARILY HIDDEN (START)
  const mfaAuthenticator = () => {
    return false;
  };

  const mfaAuthenticatorButtons = () => {
    return false;
  };

  const mfaEmail = () => {
    return false;
  };

  const mfaEmailButtons = () => {
    return false;
  };

  const mfaCodes = () => {
    return false;
  };

  const mfaCodesButtons = () => {
    return false;
  };

  const loginsView = () => {
    return false;
  };
  // XXX TEMPORARILY HIDDEN (END)

  return {
    companiesPreferred,
    companiesSelected,
    companiesInfo,
    companiesAddress,
    companiesSubdomains,
    companiesDelete,
    companiesAdd,
    companiesNoSub,
    companiesUnconfirmedSub,
    subscriptionCurrent,
    subscriptionStatus,
    subscriptionStartedOn,
    subscriptionRenewsOn,
    subscriptionEndsOn,
    subscriptionPricingPage,
    subscriptionAccessLink,
    subscriptionRenewLink,
    subscriptionChangeLink,
    subscriptionCancel,
    subscriptionRestart,
    subscriptionStartTier,
    subscriptionStartSelect,
    subscriptionStartBeginProcess,
    companyNotificationsSelected,
    companyNotificationsApproved,
    companyNotificationsTender,
    companyNotificationsReview,
    companyNotificationsConversation,
    companyNotificationsMessage,
    companyNotificationsNoSub,
    companyNotificationsUnconfirmedSub,
    companyNotificationsAdd,
    mfaPreferred,
    mfaAuthenticator,
    mfaAuthenticatorButtons,
    mfaEmail,
    mfaEmailButtons,
    mfaCodes,
    mfaCodesButtons,
    loginsView
  };
};

export default useSettingsSectionVisibility;
