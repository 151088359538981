/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import useGeneral from "../../../hooks/useGeneral";
import useAuth from "../../../hooks/useAuth";
import useServiceOffers from "../../../hooks/useServiceOffers";
import useLogger from "../../../hooks/useLogger";

import { MdCheck, MdClear, MdOutlineQuestionAnswer } from "react-icons/md";

import AvatarInfoBox from "./AvatarInfoBox";
import Button from "../../button/Button";
import Card from "../Card";
import DateInfoBox from "./DateInfoBox";
import Popup from "../../../components/popup/Popup";
import Separator from "../../../components/Separator";
import TenderInfoBox from "./TenderInfoBox";

const SmallServiceOfferCard = ({ title, tender, offer, index, refreshServiceOffers }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { userId } = useAuth();
  const { getStatusColor, showPrice } = useServiceOffers();
  const { showResponse, confirmAction } = useLogger();

  const [conversationExists, setConversationExists] = useState(false);
  const [messageButtonIsHover, setMessageButtonIsHover] = useState(false);

  const approveOffer = async () => {
    confirmAction(t("confirmApproveOffer"), async () => {
      await axios
        .patch(process.env.REACT_APP_BACKEND_URL + `service-offers/${offer.id}/approve`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          refreshServiceOffers();
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const denyOffer = async () => {
    confirmAction(t("confirmDenyOffer"), async () => {
      await axios
        .patch(process.env.REACT_APP_BACKEND_URL + `service-offers/${offer.id}/deny`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          refreshServiceOffers();
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const startConversation = async () => {
    confirmAction(t("confirmStartConversation", { name: offer.company }), async () => {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `conversations/create/${offer.companyId}/${userId()}`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
          setTimeout(() => {
            window.location.assign("/dashboard/messages");
          }, 2000);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const checkIfConversationExists = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/check/${offer.companyId}/${userId()}`, { withCredentials: true })
      .then((response) => {
        setConversationExists(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    checkIfConversationExists();
  }, [offer]);

  if (offer) {
    return (
      <Card
        title={title ? title : <AvatarInfoBox avatar={null} name={t("serviceOfferTitle", { company: offer.company })} modifiers="avatar-sm hide-avatar-mobile" />}
        modifiers="title-xs padding-sm"
        className={`flex-grow-0 flex-1/6 shadow-md ${offer.status === "approved" ? "order-first" : ""} ${
          tender.status === "ongoing" && offer.status === "other" ? "opacity-35" : ""
        } ${offer.status === "denied" ? "order-last opacity-35" : ""}`}
      >
        <div className="space-y-3">
          <div className="flex justify-between text-sm">
            <p>{t("submitted", { date: formatDate(offer.createdAt) })}</p>
            <TenderInfoBox type="status" info={t("backend.enums.serviceOfferStatuses." + offer.status)} color={getStatusColor(offer.status)} />
          </div>
          <Separator />
          <div className="space-y-3">
            <p className="whitespace-break-spaces break-words">{offer.message}</p>
            <div className="space-y-1">
              <p>{showPrice(offer)}</p>
              <div className="flex">
                <DateInfoBox start={offer.date.start} end={offer.date.end} />
              </div>
            </div>
          </div>
          {offer.status === "pending" && (
            <div className="flex justify-end space-x-2">
              <Button
                label={
                  <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                    <MdCheck />
                  </IconContext.Provider>
                }
                action={approveOffer}
                background="bg-green-500 dark:bg-green-600 hover:bg-green-400 hover:dark:bg-green-500"
                modifiers="width-5xs height-2xs no-margin"
              />
              <Button
                label={
                  <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                    <MdClear />
                  </IconContext.Provider>
                }
                action={denyOffer}
                background="bg-red-500 dark:bg-red-600 hover:bg-red-400 hover:dark:bg-red-500"
                modifiers="width-5xs height-2xs no-margin"
              />
              {(offer.status === "pending" || offer.status === "approved") && (
                <div
                  className="relative w-full max-w-5xs"
                  onMouseEnter={() => {
                    setMessageButtonIsHover(true);
                  }}
                  onMouseLeave={() => {
                    setMessageButtonIsHover(false);
                  }}
                >
                  <Button
                    label={
                      <IconContext.Provider value={{ className: "w-full h-2/3" }}>
                        <MdOutlineQuestionAnswer />
                      </IconContext.Provider>
                    }
                    action={!conversationExists ? startConversation : undefined}
                    link={conversationExists ? "/dashboard/messages" : undefined}
                    modifiers="height-2xs no-margin"
                    background={conversationExists ? "bg-gray-500 dark:bg-gray-600 hover:bg-gray-400 hover:dark:bg-gray-500" : undefined}
                  />
                  <Popup position="top" width="w-max" height="h-8" modifiers="end" shown={messageButtonIsHover}>
                    <p className="relative p-0.5 z-10 text-sm">{conversationExists ? t("goToConversations") : t("startConversation")}</p>
                  </Popup>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    );
  }

  return null;
};

export default SmallServiceOfferCard;
