/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";

import Card from "../../../components/card/Card";
import Form from "../../../components/forms/Form";

const DashboardEditTender = () => {
  const { tenderId } = useParams();

  const { t } = useTranslation();

  const { showResponse } = useLogger();

  const [formData, setFormData] = useState({
    title: "",
    description: "",
    deliverBy: "",
    urgent: false
  });

  const [tender, setTender] = useState();

  const formContent = [
    { type: "text", id: "title", label: t("title"), required: true },
    { type: "textarea", id: "description", label: t("description"), required: true },
    { type: "date", id: "deliverBy", label: t("deliverBy"), required: true },
    { type: "checkbox", id: "urgent", name: "urgent", label: t("isUrgent"), required: false }
  ];

  const getTender = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `tenders/${tenderId}`, { withCredentials: true })
      .then((response) => {
        setTender(response.data);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const editTender = async (event) => {
    event.preventDefault();

    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `tenders/${tenderId}/edit`, formData, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setTimeout(() => {
          window.location.assign(`/dashboard/tenders/${tenderId}`);
        }, [2000]);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const putTenderInfoInFormData = () => {
    setFormData({
      title: tender.title,
      description: tender.description,
      deliverBy: tender.deliverBy.split("T")[0],
      urgent: tender.urgent
    });
  };

  useEffect(() => {
    if (!tender) {
      getTender();
    }
  }, []);

  useEffect(() => {
    if (tender) {
      putTenderInfoInFormData();
    }
  }, [tender]);

  return (
    <div>
      <Card title={t("dashboardEditTender")} previous={`/dashboard/tenders/${tenderId}`}>
        <Form content={formContent} formData={formData} setFormData={setFormData} action={editTender} buttonLabel={t("editTender")} />
      </Card>
    </div>
  );
};

export default DashboardEditTender;
