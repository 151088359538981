import React, { useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";
import useLogger from "../../hooks/useLogger";

import Logo from "../../images/logo/logo-light_theme.svg";
import LogoDark from "../../images/logo/logo-dark_theme.svg";

import Card from "../../components/card/Card";
import Form from "../../components/forms/Form";

const preloadSrcList = [Logo, LogoDark];

const ForgotPassword = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();
  const { showResponse } = useLogger();

  const [formData, setFormData] = useState({
    email: ""
  });

  const forgotEmailContent = [{ type: "email", id: "email", label: t("email"), required: true }];

  const handleSubmit = async (event) => {
    event.preventDefault();

    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/forgot-password`, formData, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setFormData({ email: "" });

        setTimeout(() => {
          window.location.assign("/login");
        }, 10000);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return (
    <div className="flex flex-col items-center text-gray-600 dark:text-gray-50 w-full h-screen p-4">
      <div className="absolute w-32 h-32">
        <a href="/" className="w-full h-full">
          <img src={getActualTheme() === "dark" ? LogoDark : Logo} alt="logo" className="w-full h-full p-4" />
        </a>
      </div>
      <div className={`flex flex-col justify-center sm:w-full md:w-2/5 h-full mt-32 py-4`}>
        <Card title={t("passwordReset")} modifiers="small">
          <p>{t("enterAccountEmail")}</p>
          <br />
          <Form type="forgot-password" content={forgotEmailContent} formData={formData} setFormData={setFormData} action={handleSubmit} />
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;
