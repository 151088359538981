import { useContext } from "react";

import { DimensionsContext } from "../../contexts/DimensionsContext";

const useSettingsJSDirections = () => {
  const dimensionsContext = useContext(DimensionsContext);

  const companiesSubdomainsCheckboxes = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  return {
    companiesSubdomainsCheckboxes
  };
};

export default useSettingsJSDirections;
