import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { MdAddCircleOutline } from "react-icons/md";

import useConversations from "../../../hooks/useConversations";

import Avatar from "../../avatar/Avatar";

const ConversationPreview = ({ type, preview = null, selectedId, onClick, link, modifiers = "" }) => {
  const { t } = useTranslation();

  const { getProfilePic, messagePreviewTimestamp } = useConversations();

  const timestampRef = useRef(null);

  const [timestampHovered, setTimestampHovered] = useState(false);

  if (type !== "add" && preview !== null) {
    return (
      <div
        className={`flex items-center min-h-10 3xl:min-h-16 max-h-14 3xl:max-h-20 ${
          modifiers.includes("small-home") ? "p-1.5 3xl:p-2" : "p-2"
        } space-x-3 rounded-md hover:bg-neutral-200 dark:hover:bg-neutral-800 hover:cursor-pointer ${selectedId === preview._id ? "bg-neutral-200 dark:bg-neutral-800" : ""}`}
        onClick={() => {
          if (onClick) {
            onClick(preview._id);
          } else if (link) {
            window.location.assign(link);
          }
        }}
      >
        <div className={`${modifiers.includes("small-home") ? "w-9 h-9" : "w-10 h-10"} 3xl:w-12 3xl:h-12`}>
          <Avatar image={getProfilePic(preview.recipient.pfp)} alt={"Profile picture for " + preview.recipient.name} status={preview.recipient.onlineStatus} />
        </div>
        <div className="flex-1 space-y-1 min-w-0 whitespace-nowrap text-xs 3xl:text-sm">
          <p className="max-w-3.5xs 3xl:max-w-2xs font-bold text-ellipsis overflow-hidden">{preview.recipient.name}</p>
          <p className={`max-w-3.5xs 3xl:max-w-2xs text-ellipsis overflow-hidden ${!preview.lastMessage ? "hidden" : ""}`}>
            {preview.lastMessage && (
              <>
                {preview.lastMessage.isAuthor ? t("you") : ""}
                <span className={`${!preview.lastMessage.text ? "italic" : ""}`}>{preview.lastMessage.text || t("image")}</span>
              </>
            )}
          </p>
        </div>
        <div className="text-2xs">{preview.lastMessage && messagePreviewTimestamp(preview.lastMessage.timestamp, timestampRef, timestampHovered, setTimestampHovered)}</div>
      </div>
    );
  }

  if (type === "add") {
    return (
      <div
        className="flex items-center min-h-10 3xl:min-h-16 max-h-14 3xl:max-h-20 p-2 space-x-3 rounded-md hover:bg-neutral-200 dark:hover:bg-neutral-800 hover:cursor-pointer"
        onClick={onClick}
      >
        <div className="w-10 3xl:w-12 h-10 3xl:h-12">
          <MdAddCircleOutline className="w-full h-full" />
        </div>
        <div className="flex-1 space-y-1 min-w-0 whitespace-nowrap text-sm">
          <p className="font-bold text-ellipsis overflow-hidden">{t("startConversation")}</p>
        </div>
      </div>
    );
  }

  return null;
};

export default ConversationPreview;
