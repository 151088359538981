import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const Step = ({ num, isActive, submitted, switchToPage }) => {
  return (
    <div className="relative">
      <div
        className={`w-8 h-8 lg:w-10 lg:h-10 flex items-center justify-center shrink-0 border-2 rounded-full font-semibold text-xs lg:text-sm relative z-10 transition-colors duration-300 cursor-pointer ${
          isActive || submitted ? "border-hoptop-600 bg-hoptop-600 text-white" : "border-gray-300 text-gray-300"
        }`}
        onClick={() => {
          switchToPage(num - 1);
        }}
      >
        <AnimatePresence mode="wait">
          {isActive || submitted ? (
            <motion.svg
              key="icon-marker-check"
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 16 16"
              height="1.2em"
              width="1.2em"
              xmlns="http://www.w3.org/2000/svg"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z"></path>
            </motion.svg>
          ) : (
            <motion.span
              key="icon-marker-num"
              initial={{ rotate: 180, opacity: 0 }}
              animate={{ rotate: 0, opacity: 1 }}
              exit={{ rotate: -180, opacity: 0 }}
              transition={{ duration: 0.125 }}
            >
              {num}
            </motion.span>
          )}
        </AnimatePresence>
      </div>
      {isActive || submitted ? <div className="absolute z-0 -inset-1 bg-hoptop-100 dark:bg-hoptop-900 rounded-full animate-pulse" /> : ""}
    </div>
  );
};

export default Step;
