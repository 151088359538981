import React, { useState, useEffect, useRef, useContext } from "react";
import { IconContext } from "react-icons";

import { MdLanguage } from "react-icons/md";

import { DimensionsContext } from "../../contexts/DimensionsContext";

import LanguageButton from "./elements/LanguageButton";
import Popup from "../../components/popup/Popup";

const LanguageSelector = ({ className }) => {
  const dimensionsContext = useContext(DimensionsContext);

  const selectorRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOutsideClick = (event) => {
    if (menuOpen && !selectorRef.current?.contains(event.target)) {
      toggleMenu();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div ref={selectorRef} className={`relative min-w-4 max-w-6 h-6 cursor-pointer ${className}`}>
      <IconContext.Provider value={{ className: "w-full h-full text-black dark:text-white" }}>
        <MdLanguage onClick={toggleMenu} />
      </IconContext.Provider>
      <Popup
        position={!dimensionsContext.isMobile ? "bottom" : "top"}
        width="w-32"
        height="min-h-10"
        background={!dimensionsContext.isMobile ? undefined : "bg-neutral-350 dark:bg-neutral-750"}
        shown={menuOpen}
      >
        <div className="space-y-1">
          {["fr", "en"].map((code) => {
            return <LanguageButton key={code} code={code} toggleMenu={toggleMenu} />;
          })}
        </div>
      </Popup>
    </div>
  );
};

export default LanguageSelector;
