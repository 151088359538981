/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import axios from "axios";
import { Buffer } from "buffer";
import { dotPulse } from "ldrs";

import { MdAdd } from "react-icons/md";

import useConversations from "../../../hooks/useConversations";
import useDarkTheme from "../../../hooks/useDarkTheme";
import useLogger from "../../../hooks/useLogger";
import { DimensionsContext } from "../../../contexts/DimensionsContext";
import { ImageOverlayContext } from "../../../contexts/ImageOverlayContext";
import { InfoOverlayContext } from "../../../contexts/InfoOverlayContext";
import { useTranslation } from "react-i18next";

const Message = ({ conversationId, message, lastInBunch, loading }) => {
  const { t } = useTranslation();

  dotPulse.register();

  const { userIsAuthor } = useConversations();
  const { getActualTheme } = useDarkTheme();
  const { logResponse } = useLogger();
  const dimensionsContext = useContext(DimensionsContext);
  const imageOverlay = useContext(ImageOverlayContext);
  const infoOverlay = useContext(InfoOverlayContext);

  const [images, setImages] = useState([]);

  const getImages = async () => {
    // FIXME Make sure the images are received faster than this... maybe compress them in the backend before sending them?

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/${conversationId}/${message._id}/images`, { withCredentials: true })
      .then((response) => {
        const newImages = [];

        response.data.images.forEach((image) => {
          newImages.push("data:image/png;base64," + Buffer.from(image, "binary").toString("base64"));
        });

        setImages(newImages);
      })
      .catch((e) => {
        logResponse(e);
      });
  };

  useEffect(() => {
    if (message.images?.length > 0) {
      if (message.images[0].includes("data")) {
        setImages(message.images);
      } else {
        getImages();
      }
    }
  }, [message]);

  return (
    <div
      className={`relative w-fit max-w-[95%] lg:max-w-3/4 p-4 rounded-md ${lastInBunch && "message-arrow"} ${
        userIsAuthor(message)
          ? "bg-blue-500 text-white dark:bg-blue-900 mr-3 self-end message-arrow-right"
          : "bg-neutral-250 dark:bg-neutral-950 ml-3 self-start message-arrow-left"
      }`}
    >
      {loading ? (
        <l-dot-pulse size="43" speed="1.3" color={getActualTheme() === "dark" ? "white" : "black"} />
      ) : (
        <div className={`flex flex-col ${userIsAuthor(message) ? "items-end" : "items-start"}`}>
          {message.images && (
            <div
              className={`flex ${images?.length > 4 ? "flex-col" : ""} gap-1 flex-wrap w-full aspect-square min-[420px]:aspect-auto min-[420px]:w-56 min-[420px]:h-56 ${
                images?.length < 5 ? "lg:w-64" : ""
              } ${images?.length === 2 ? "lg:w-96" : ""} ${images?.length > 4 && images?.length < 7 ? "lg:w-96" : ""} ${images?.length > 6 ? "lg:w-[32.5rem]" : ""} lg:h-64 ${
                message.text && "mb-4"
              } rounded-md overflow-hidden`}
            >
              {images?.length > 0 &&
                message.images.map((image, i) => {
                  const imageData = images[i];

                  const twoPerRow =
                    (images.length === 2 && (i === 0 || i === 1)) ||
                    (images.length === 3 && (i === 0 || i === 1)) ||
                    (images.length === 4 && (i === 0 || i === 1 || i === 2 || i === 3));
                  const onePerRow = images.length === 3 && i === 2;
                  const twoPerCol =
                    (images.length === 5 && (i === 0 || i === 1 || i === 2 || i === 3)) ||
                    (images.length === 6 && (i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5)) ||
                    (images.length === 7 && (i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5)) ||
                    (images.length === 8 && (i === 0 || i === 1 || i === 2 || i === 3 || i === 4 || i === 5 || i === 6 || i === 7));
                  const onePerColSmall = images.length === 5 && i === 4;
                  const onePerColBig = images.length === 7 && i === 6;

                  if (images.length > 4 && dimensionsContext.isMobile && i === 3) {
                    return (
                      <div
                        key={i}
                        onClick={() => {
                          infoOverlay.setTitle(t("allImages"));
                          infoOverlay.setInfo(
                            <div className="flex flex-wrap gap-1 rounded-md overflow-hidden">
                              {message.images.map((image, j) => {
                                const imageData = images[j];

                                return (
                                  <img
                                    key={j}
                                    src={imageData}
                                    alt={message._id}
                                    onClick={() => {
                                      imageOverlay.setImage(images[j]);
                                    }}
                                    className={`${
                                      (images.length === 5 && j === 4) || (images.length === 7 && j === 6) ? "w-full aspect-[2/1]" : "w-[calc(50%-0.125rem)] aspect-square"
                                    } object-cover cursor-pointer`}
                                  />
                                );
                              })}
                            </div>
                          );
                        }}
                        className="relative w-[calc(50%-0.125rem)] cursor-pointer"
                      >
                        <img src={imageData} alt={message._id} className={`w-full aspect-square object-cover brightness-[0.35]`} />
                        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-16 h-16">
                          <IconContext.Provider value={{ className: "w-full h-full" }}>
                            <MdAdd />
                          </IconContext.Provider>
                        </div>
                      </div>
                    );
                  }

                  if (!dimensionsContext.isMobile || (dimensionsContext.isMobile && i < 4)) {
                    return (
                      <img
                        key={i}
                        src={imageData}
                        alt={message._id}
                        onClick={() => {
                          imageOverlay.setImage(images[i]);
                        }}
                        className={`${
                          twoPerRow
                            ? "w-[calc(50%-0.125rem)]"
                            : onePerRow
                            ? "w-full h-[calc(50%-0.125rem)]"
                            : twoPerCol
                            ? "h-[calc(50%-0.125rem)]"
                            : onePerColSmall
                            ? "w-[calc(33%-0.125rem)] h-full"
                            : onePerColBig
                            ? "w-[calc(25%-0.125rem)] h-full"
                            : ""
                        } aspect-square object-cover cursor-pointer`}
                      />
                    );
                  }

                  return null;
                })}
            </div>
          )}
          {message.text && (
            <p lang="en" className={`whitespace-break-spaces break-words ${userIsAuthor(message) ? "text-end" : "text-start"}`}>
              {message.text}
            </p>
          )}
        </div>
      )}
    </div>
  );
};

export default Message;
