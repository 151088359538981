import { useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useStripe from "../../hooks/useStripe";
import useAuth from "../../hooks/useAuth";
import useLogger from "../../hooks/useLogger";
import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

const useStoredFunctions = () => {
  const { t } = useTranslation();

  const STRIPE = useStripe();
  const { switchToUser, hasPendingSub } = useAuth();
  const { showResponse, confirmAction, askForInput, askForPassword } = useLogger();
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  // Companies
  const deleteCompany = async () => {
    const { isConfirmed } = await confirmAction(t("confirmDeleteCompany"));

    if (isConfirmed) {
      const { value: password } = await askForPassword();

      if (password) {
        await axios
          .delete(process.env.REACT_APP_BACKEND_URL + `companies/${settingsFormDataContext.formData.companiesSelectedDropdown}`, {
            data: { password: password },
            withCredentials: true
          })
          .then((response) => {
            showResponse(response);
            switchToUser();

            setTimeout(() => {
              window.location.assign("/dashboard");
            }, 2000);
          })
          .catch((e) => {
            showResponse(e);
          });
      }
    }
  };

  // Subscription
  const stripeStart = () => {
    if (hasPendingSub()) {
      STRIPE.renewSubscriptionPage(false);
    } else {
      STRIPE.openSubscriptionPage();
    }
  };

  const stripeRenew = () => {
    STRIPE.renewSubscriptionPage();
  };

  const stripeCancel = () => {
    STRIPE.cancelMySubscription();
  };

  const stripeRestart = () => {
    STRIPE.restartMySubscription();
  };

  // MFA
  const setupApp = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-otp`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Show QR code from link provided in response, then ask for code and send it to PATCH users/confirm-otp
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeApp = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-otp`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const setupSMS = async () => {
    const { value: phone } = await askForInput(t("mfaSMSTitle"), t("mfaSMSEnterPhone"), "tel", t("phone"));

    if (phone) {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `users/setup-phone`, { phone: phone }, { withCredentials: true })
        .then(async (response) => {
          const codeValidation = (value) => {
            const CODE_REGEX = /^[0-9]{6}$/g;

            if (!value) {
              return false;
            } else {
              return CODE_REGEX.test(value);
            }
          };

          const sendCodeToBackend = async (code) => {
            if (code) {
              await axios
                .patch(process.env.REACT_APP_BACKEND_URL + `users/confirm-phone`, { code: code }, { withCredentials: true })
                .then((response) => {
                  showResponse(response);

                  settingsFormDataContext.updateUser();
                })
                .catch(async (e) => {
                  const { value: code } = await askForInput(t("mfaSMSTitle"), t("mfaSMSReenterCode"), "tel", t("code"), codeValidation, t("backend.error.invalidCode"));

                  sendCodeToBackend(code);
                });
            }
          };

          const { value: code } = await askForInput(
            t("mfaSMSTitle"),
            t("backend.success.generatedRecoveryPhone"),
            "tel",
            t("code"),
            codeValidation,
            t("backend.error.invalidCode")
          );

          sendCodeToBackend(code);
        })
        .catch((e) => {
          showResponse(e);
        });
    }
  };

  const removeSMS = async () => {
    confirmAction(t("confirmRemoveMFA", { method: t("sms") }), async () => {
      await axios
        .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-phone`, { withCredentials: true })
        .then((response) => {
          showResponse(response);

          settingsFormDataContext.updateUser();
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const setupEmail = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-email`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Ask for recovery email, then ask for code and send it to PATCH users/confirm-email
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeEmail = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-email`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const setupCodes = async () => {
    await axios
      .post(process.env.REACT_APP_BACKEND_URL + `users/setup-codes`, null, { withCredentials: true })
      .then((response) => {
        // TODO: Show codes contained in response
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  const removeCodes = async () => {
    await axios
      .delete(process.env.REACT_APP_BACKEND_URL + `users/delete-codes`, null, { withCredentials: true })
      .then((response) => {
        showResponse(response);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  // Logins
  const logoutEverywhere = async () => {
    confirmAction(t("confirmLogoutEverywhere"), async () => {
      await axios
        .post(process.env.REACT_APP_BACKEND_URL + `users/logout-everywhere`, null, { withCredentials: true })
        .then((response) => {
          showResponse(response);
        })
        .catch((e) => {
          showResponse(e);
        });
    });
  };

  const deleteAccount = async () => {
    const { isConfirmed } = await confirmAction(t("confirmDeleteAccount"));

    if (isConfirmed) {
      const { value: reason, isDismissed } = await askForInput(t("deletionReason"), t("specifyReason"), "textarea", t("reason"));

      if (!isDismissed) {
        const { value: password } = await askForPassword();

        if (password) {
          await axios
            .delete(process.env.REACT_APP_BACKEND_URL + `users/current-user`, { data: { password: password, reason: reason }, withCredentials: true })
            .then((response) => {
              showResponse(response);

              setTimeout(() => {
                window.location.assign("/");
              }, 10000);
            })
            .catch((e) => {
              showResponse(e);
            });
        }
      }
    }
  };

  return {
    deleteCompany,
    stripeStart,
    stripeRenew,
    stripeCancel,
    stripeRestart,
    setupApp,
    removeApp,
    setupSMS,
    removeSMS,
    setupEmail,
    removeEmail,
    setupCodes,
    removeCodes,
    logoutEverywhere,
    deleteAccount
  };
};

export default useStoredFunctions;
