/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconContext } from "react-icons";

import { MdArrowDropDown, MdArrowDropUp, MdInfoOutline, MdVisibility, MdVisibilityOff } from "react-icons/md";

import useLogger from "../../../hooks/useLogger";

const Input = ({ type = "text", formData, setFormData, id, from, index, label, required, disabled, hidden, value, onChange, onFocus, onBlur, className, modifiers = "" }) => {
  const { t } = useTranslation();

  const { showMessage } = useLogger();

  const inputRef = useRef(null);

  const [focused, setFocused] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const focusInput = (actuallyFocus = true) => {
    setFocused(true);

    if (actuallyFocus) {
      inputRef.current.focus();
    }
  };

  const unfocusInput = () => {
    if (inputRef.current.value === "") {
      setFocused(false);
    }
  };

  const formOnChange = (event) => {
    let value = event.target.value;

    if (!from) {
      if (type === "num") {
        if (id.match("[A-Za-z]\\[[0-9]\\]")) {
          let temp = id.split("[");
          temp[1] = parseInt(temp[1].substring(0, 1));

          let tempArray = formData[temp[0]];
          let newValue = parseInt(value);

          if (!isNaN(newValue)) {
            tempArray[temp[1]] = newValue;
            setFormData({
              ...formData,
              [temp[0]]: tempArray
            });
          } else if (value === "") {
            tempArray[temp[1]] = value;
            setFormData({
              ...formData,
              [temp[0]]: tempArray
            });
          }
        } else {
          let newValue = parseInt(value);

          if (!isNaN(newValue)) {
            setFormData({ ...formData, [id]: newValue });
          } else if (value === "") {
            setFormData({ ...formData, [id]: value });
          }
        }
      } else if (type === "date") {
        if (id.match("[A-Za-z]\\.[A-Za-z]")) {
          let temp = id.split(".");
          let tempObject = formData[temp[0]];

          setFormData({
            ...formData,
            [temp[0]]: { ...tempObject, [temp[1]]: value }
          });
        } else {
          setFormData({
            ...formData,
            [id]: value
          });
        }
      } else {
        setFormData({ ...formData, [id]: value });
      }
    } else {
      let array = [...formData[from]];

      if (type === "num" && value !== "") {
        array[index].a = parseInt(value);
      } else {
        array[index].a = value;
      }

      setFormData({ ...formData, [from]: array });
    }
  };

  const PATTERN = type === "phone" ? "^\\+?\\d?[\\-\\s]?\\(?\\d{3}\\)?[\\-\\s]?\\d{3}[\\-\\s]?\\d{4}$" : undefined;
  const TYPE = type === "password" ? (passwordShown ? "text" : "password") : type === "phone" ? "tel" : type === "num" ? "number" : type;
  const REF_CURRENT_VALUE = inputRef.current !== null ? inputRef.current.value : null;

  useEffect(() => {
    if (inputRef.current.value !== "") {
      focusInput(false);
    }
  }, [REF_CURRENT_VALUE]);

  useEffect(() => {
    inputRef.current.addEventListener("onStep", (event) => {
      if (formData) {
        formOnChange(event);
      } else {
        onChange(event);
      }
    });
  }, [formData]);

  return (
    <div
      className={`relative flex flex-col w-full ${hidden ? "hidden" : ""} ${modifiers.includes("width-xs") ? "max-w-xs" : ""} ${modifiers.includes("width-sm") ? "max-w-sm" : ""} ${
        modifiers.includes("width-md") ? "max-w-md" : ""
      } ${modifiers.includes("width-lg") ? "max-w-lg" : ""} ${modifiers.includes("width-xl") ? "max-w-xl" : ""} ${modifiers.includes("width-1/2") ? "max-w-1/2" : ""} ${
        modifiers.includes("width-1/4") ? "max-w-1/4" : ""
      } ${modifiers.includes("width-3/4") ? "max-w-3/4" : ""}`}
      onClick={focusInput}
    >
      <label
        htmlFor={id}
        className={`absolute top-1/2 -translate-y-1/2 ml-2 px-1 text-neutral-500 dark:text-neutral-300 transition-[top,font-size] pointer-events-none ${
          type === "textarea" ? "top-6" : ""
        } ${focused ? "text-xs bg-white dark:bg-neutral-750 !top-0" : ""} ${disabled ? `!text-neutral-350 dark:!text-neutral-500` : ""} ${
          modifiers.includes("secondary") ? "!bg-neutral-150 dark:!bg-neutral-700" : ""
        }`}
      >
        {label}
      </label>
      {type !== "textarea" && (
        <input
          ref={inputRef}
          type={TYPE}
          id={id}
          name={id}
          pattern={PATTERN}
          required={required}
          disabled={disabled}
          value={formData ? (!from ? formData[id] : formData[from][index].a) : value}
          onChange={(event) => {
            if (event.target.value !== "") {
              focusInput(false);
            }

            if (!onChange) {
              formOnChange(event);
            } else {
              onChange(event);
            }
          }}
          onFocus={(event) => {
            focusInput();

            if (onFocus) {
              onFocus(event);
            }

            if (type === "date") {
              const now = new Date();
              const date = {
                year: now.getFullYear(),
                month: (now.getMonth() < 9 ? "0" : "") + (now.getMonth() + 1),
                day: now.getDate()
              };
              event.target.min = `${date.year}-${date.month}-${date.day}`;
              event.target.max = `${date.year + 1}-${date.month}-${date.day}`;
            }
          }}
          onBlur={(event) => {
            unfocusInput();

            if (onBlur) {
              onBlur(event);
            }
          }}
          onDoubleClick={
            type === "date"
              ? (event) => {
                  event.target.showPicker();
                }
              : undefined
          }
          className={`bg-transparent outline-none border dark:border-neutral-700 focus:border-neutral-500 focus:dark:border-neutral-300 p-3 shadow-sm rounded-lg ${
            type === "num" ? "no-spinners" : ""
          } ${type === "password" ? (modifiers.includes("passwordRequirements") ? "pr-[4.25rem]" : "pr-10") : ""} ${
            disabled ? `!bg-neutral-150 dark:!bg-neutral-700 !text-neutral-450 dark:!text-neutral-400` : ""
          } ${type === "date" && !focused ? "!text-transparent dark:!text-transparent" : ""} ${className} ${
            modifiers.includes("secondary") ? "!border-neutral-300 dark:!border-neutral-600 focus:!border-neutral-500 focus:dark:!border-neutral-300" : ""
          }`}
        />
      )}
      {type === "textarea" && (
        <textarea
          ref={inputRef}
          id={id}
          name={id}
          required={required}
          disabled={disabled}
          value={formData ? formData[id] : value}
          onChange={(event) => {
            if (event.target.value !== "") {
              focusInput(false);
            }

            if (formData) {
              formOnChange(event);
            } else {
              onChange(event);
            }
          }}
          onFocus={(event) => {
            focusInput();

            if (onFocus) {
              onFocus(event);
            }
          }}
          onBlur={(event) => {
            unfocusInput();

            if (onBlur) {
              onBlur(event);
            }
          }}
          className={`bg-transparent outline-none border dark:border-neutral-700 focus:border-neutral-500 focus:dark:border-neutral-300 p-3 shadow-sm rounded-lg  ${
            disabled ? `!bg-neutral-150 dark:!bg-neutral-700 !text-neutral-450 dark:!text-neutral-400` : ""
          } ${className}`}
        />
      )}

      {type === "password" && (
        <>
          <button
            type="button"
            onClick={(event) => {
              event.stopPropagation();

              if (!disabled) {
                setPasswordShown(!passwordShown);
              }
            }}
            className={`absolute flex items-center justify-center h-full inset-y-0 right-0 ${modifiers.includes("passwordRequirements") ? "pr-10" : "pr-3"} ${
              disabled ? "cursor-default" : ""
            }`}
          >
            <IconContext.Provider
              value={{
                className: `w-5 h-5 text-neutral-950 dark:text-neutral-50 ${
                  !disabled ? "transition-transform hover:scale-110 active:scale-100" : "!text-neutral-450 dark:!text-neutral-400"
                }`
              }}
            >
              {passwordShown ? <MdVisibility /> : <MdVisibilityOff />}
            </IconContext.Provider>
          </button>
          {modifiers.includes("passwordRequirements") && (
            <button
              type="button"
              onClick={(event) => {
                event.stopPropagation();

                showMessage(
                  t("passwordRequirements.title"),
                  `${t("passwordRequirements.length")}\n\n${t("passwordRequirements.case")}\n\n${t("passwordRequirements.characters")}\n\n${t("passwordRequirements.name")}\n\n${t(
                    "passwordRequirements.sequence"
                  )}`
                );
              }}
              className={`absolute flex items-center justify-center h-full inset-y-0 right-0 pr-3 ${disabled ? "cursor-default" : ""}`}
            >
              <IconContext.Provider
                value={{
                  className: `w-5 h-5 text-neutral-950 dark:text-neutral-50 ${
                    !disabled ? "transition-transform hover:scale-110 active:scale-100" : "!text-neutral-450 dark:!text-neutral-400"
                  }`
                }}
              >
                {<MdInfoOutline />}
              </IconContext.Provider>
            </button>
          )}
        </>
      )}
      {type === "num" && (
        <div className={`absolute flex flex-col items-center justify-center h-full inset-y-0 right-0 px-3 py-2`}>
          <button
            type="button"
            tabIndex={-1}
            onClick={(event) => {
              event.stopPropagation();

              if (!disabled) {
                focusInput(false);
                inputRef.current.stepUp();
                inputRef.current.dispatchEvent(new Event("onStep"));
              }
            }}
            className=" active:scale-100"
          >
            <IconContext.Provider
              value={{
                className: `w-5 h-5 text-neutral-950 dark:text-neutral-50 ${
                  !disabled ? "transition-transform hover:scale-125 active:scale-100" : "!text-neutral-450 dark:!text-neutral-400 cursor-default"
                }`
              }}
            >
              <MdArrowDropUp />
            </IconContext.Provider>
          </button>
          <button
            type="button"
            tabIndex={-1}
            onClick={(event) => {
              event.stopPropagation();

              if (!disabled) {
                focusInput(false);
                inputRef.current.stepDown();
                inputRef.current.dispatchEvent(new Event("onStep"));
              }
            }}
          >
            <IconContext.Provider
              value={{
                className: `w-5 h-5 text-neutral-950 dark:text-neutral-50 ${
                  !disabled ? "transition-transform hover:scale-125 active:scale-100" : "!text-neutral-450 dark:!text-neutral-400 cursor-default"
                }`
              }}
            >
              <MdArrowDropDown />
            </IconContext.Provider>
          </button>
        </div>
      )}
    </div>
  );
};

export default Input;
