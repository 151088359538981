import React, { useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";
import useLogger from "../../hooks/useLogger";

import Logo from "../../images/logo/logo-light_theme.svg";
import LogoDark from "../../images/logo/logo-dark_theme.svg";

import Card from "../../components/card/Card";
import Form from "../../components/forms/Form";

const preloadSrcList = [Logo, LogoDark];

const ResetPassword = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { showResponse, showError } = useLogger();
  const { getActualTheme } = useDarkTheme();
  const { userId, resetCode } = useParams();

  const [formData, setFormData] = useState({
    newPassword: "",
    confirmNewPassword: ""
  });

  const forgotPasswordContent = [
    { type: "password", id: "newPassword", label: t("newPassword"), required: true, modifiers: "passwordRequirements" },
    { type: "password", id: "confirmNewPassword", label: t("confirmPassword"), required: true }
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.newPassword !== formData.confirmNewPassword) {
      return showError("backend.error.passwordsDoNotMatch");
    }

    await axios
      .patch(process.env.REACT_APP_BACKEND_URL + `users/reset-password/${userId}/${resetCode}`, formData, { withCredentials: true })
      .then((response) => {
        showResponse(response);

        setFormData({ newPassword: "", confirmNewPassword: "" });

        setTimeout(() => {
          window.location.assign("/login");
        }, 2500);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  return (
    <div className="flex flex-col items-center text-gray-600 dark:text-gray-50 w-full h-screen p-4">
      <div className="absolute w-32 h-32">
        <a href="/" className="w-full h-full">
          <img src={getActualTheme() === "dark" ? LogoDark : Logo} alt="logo" className="w-full h-full p-4" />
        </a>
      </div>
      <div className={`flex flex-col justify-center sm:w-full md:w-2/5 h-full mt-32 py-4`}>
        <Card title={t("passwordReset")} modifiers="small">
          <p>{t("createNewPassword")}</p>
          <br />
          <Form type="forgot-password" content={forgotPasswordContent} formData={formData} setFormData={setFormData} action={handleSubmit} />
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
