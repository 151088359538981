import React from "react";
import { IconContext } from "react-icons";

const InfoOverlayIcon = ({ icon, action }) => {
  return (
    <div className="w-10 h-10 cursor-pointer transition-transform hover:scale-110 active:scale-105" onClick={action}>
      <IconContext.Provider value={{ className: "w-full h-full" }}>{icon}</IconContext.Provider>
    </div>
  );
};

export default InfoOverlayIcon;
