/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useDarkTheme from "../../hooks/useDarkTheme";

import TenderCard from "../card/dashboard/TenderCard";

const TendersList = ({ type = "tenders", tenders, getMoreTenders, isLastBatch }) => {
  ring2.register();

  const { t } = useTranslation();

  const tenderListRef = useRef(null);

  const { getActualTheme } = useDarkTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [endMessageShown, setEndMessageShown] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const onScroll = (event) => {
    if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
      if (!isLoading) {
        setIsLoading(true);
        getMoreTenders(setIsLoading);
      }

      if (isLastBatch) {
        setEndMessageShown(true);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <= 3));
    };

    // FIXME Along with BigServiceOffersList.jsx, ServiceOffersDisplay.jsx, SettingContent.jsx, DashboardHome.jsx, DashboardMessages.jsx & DashboardTender.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(tenderListRef.current.scrollTop);
    setScrollIsBottom(Math.abs(tenderListRef.current.scrollHeight - tenderListRef.current.scrollTop - tenderListRef.current.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    tenderListRef.current.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      tenderListRef.current?.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  return (
    <div
      ref={tenderListRef}
      onScroll={onScroll}
      className={`flex flex-col space-y-4 pr-2 overflow-y-scroll scrollbar-preset ${
        !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
      }`}
    >
      {tenders === null ? (
        <p>{t("fetchingTenders")}</p>
      ) : tenders.length > 0 ? (
        <>
          {tenders.map((tender) => {
            return (
              <TenderCard
                key={tender._id}
                id={tender._id}
                addr={tender.addr}
                createdAt={tender.createdAt}
                deliverBy={tender.deliverBy}
                description={tender.description}
                city={tender.city}
                subdomain={tender.subdomain}
                urgent={tender.urgent}
                status={tender.status}
                title={tender.title}
                user={tender.author}
                loggedUserIsAuthor={tender.loggedUserIsAuthor}
              />
            );
          })}
          {!isLastBatch && (
            <div className={`flex items-center justify-center pt-4 pb-8 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
              <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
            </div>
          )}
          {isLastBatch && endMessageShown && (
            <div className={`flex items-center justify-center py-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
              <p className="text-lg">
                {type === "tenders" ? t("reachedTendersEnd") : type === "myTenders" ? t("reachedMyTendersEnd") : type === "myProjects" && t("reachedMyProjectsEnd")}
              </p>
            </div>
          )}
        </>
      ) : (
        <p>{type === "tenders" ? t("noTenders") : type === "myTenders" ? t("noMyTenders") : type === "myProjects" && t("noMyProjects")}</p>
      )}
    </div>
  );
};

export default TendersList;
