/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { Buffer } from "buffer";
import { useTranslation } from "react-i18next";
import { ring2 } from "ldrs";

import useAuth from "../../../hooks/useAuth";
import useConversations from "../../../hooks/useConversations";
import useDarkTheme from "../../../hooks/useDarkTheme";
import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";
import { UserSocketContext } from "../../../contexts/UserSocketContext";

import Conversation from "../../../components/chat/Conversation";
import ConversationPreview from "../../../components/chat/elements/ConversationPreview";
import ConversationPreviewList from "../../../components/chat/ConversationPreviewList";

const DashboardMessages = () => {
  ring2.register();

  const { t } = useTranslation();

  const previewsRef = useRef(null);

  const { isCompany } = useAuth();
  const { sortConversations } = useConversations();
  const { getActualTheme } = useDarkTheme();
  const { showResponse, showMessage, logResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);
  const userSocket = useContext(UserSocketContext);

  const [mobileConversationSelected, setMobileConversationSelected] = useState(false);

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollIsTop, setScrollIsTop] = useState(true);
  const [scrollIsBottom, setScrollIsBottom] = useState(false);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [endMessageShown, setEndMessageShown] = useState(false);
  const [previews, setPreviews] = useState();
  const [conversations, setConversations] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const getPreviewsAsUser = async (date) => {
    let newPreviews = [];

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/user${date ? `/${date}` : ""}`, {
        withCredentials: true
      })
      .then((response) => {
        if (!date) {
          setPreviews(response.data.conversations);
        } else {
          newPreviews = response.data.conversations;

          setIsLastBatch(response.data.isLastConversations);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newPreviews;
  };

  const getPreviewsAsCompany = async (date) => {
    let newPreviews = [];

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `conversations/${!selectedCompany.isNull ? `company/${selectedCompany.id}${date ? `/${date}` : ""}` : "user"}`, {
        withCredentials: true
      })
      .then((response) => {
        if (!date) {
          setPreviews(response.data.conversations);
        } else {
          newPreviews = response.data.conversations;

          setIsLastBatch(response.data.isLastConversations);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newPreviews;
  };

  const addNewPreviewsToList = async (newPreviews) => {
    newPreviews.forEach((preview) => {
      previews.push(preview);
    });
  };

  const getMorePreviews = async (setIsLoading) => {
    if (!isLastBatch) {
      const date = previews[previews.length - 1].updatedAt;
      const formattedDate = date.substring(0, date.length - 1);

      const newPreviews = !isCompany() ? await getPreviewsAsUser(formattedDate) : await getPreviewsAsCompany(formattedDate);
      addNewPreviewsToList(newPreviews);

      setIsLoading(false);
    }
  };

  const getConversationMessages = async (id, index = 0) => {
    const { data } = await axios.get(process.env.REACT_APP_BACKEND_URL + `conversations/${id}/${index}`, { withCredentials: true }).catch((e) => {
      showResponse(e);
      return null;
    });

    if (selectedId) {
      const conversation = getConversationById(id);
      conversation.lastMessagesIndexLoaded = !data.isLastMessages ? index : -1;
    }

    return data.messages;
  };

  const populateConversations = async () => {
    if (previews?.length > 0) {
      const conversations = await Promise.all(
        previews.map(async (preview) => {
          const previewCopy = { ...preview };
          delete previewCopy.lastMessage;

          return {
            ...previewCopy,
            messages: await getConversationMessages(previewCopy._id),
            lastMessagesIndexLoaded: 0
          };
        })
      );

      setConversations(conversations);
    }
  };

  const getConversationById = (id, source = conversations) => {
    return source.find((conversation) => conversation._id === id);
  };

  const onPreviewClick = (id) => {
    setSelectedId(id);
  };

  const addMessageToConversation = async (conversationId, message, hasImages) => {
    if (hasImages) {
      message.images = await getMessageImages(conversationId, message._id);
    }

    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.messages.unshift(message);
      conversation.length > 25 && conversation.messages.pop();
      sortConversations(prevState);
      return [...prevState];
    });
  };

  const addOldMessagesToConversation = async (conversationId, messages) => {
    const conversation = getConversationById(conversationId);

    messages.forEach((message) => {
      conversation.messages.push(message);
    });
  };

  const fetchMoreMessages = async (conversationId, setIsLoading) => {
    const conversation = getConversationById(conversationId);

    if (conversation.lastMessagesIndexLoaded !== -1) {
      const newMessages = await getConversationMessages(conversationId, conversation.lastMessagesIndexLoaded + 1);
      addOldMessagesToConversation(conversationId, newMessages);

      setIsLoading(false);
    }
  };

  const getMessageImages = async (conversationId, messageId) => {
    const { data } = await axios.get(process.env.REACT_APP_BACKEND_URL + `conversations/${conversationId}/${messageId}/images`, { withCredentials: true }).catch((e) => {
      logResponse(e);
      return null;
    });

    const newImages = [];

    data.images.forEach((image) => {
      newImages.push("data:image/png;base64," + Buffer.from(image, "binary").toString("base64"));
    });

    return newImages;
  };

  const changeRecipientStatus = (conversationId, status) => {
    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.recipient.status = status;
      return [...prevState];
    });
  };

  const changeTypingStatus = (conversationId, typing) => {
    setConversations((prevState) => {
      const conversation = getConversationById(conversationId, prevState);
      conversation.recipient.typing = typing;
      return [...prevState];
    });
  };

  const onScroll = (event) => {
    if (event.target.scrollTop + event.target.offsetHeight >= event.target.scrollHeight) {
      if (!isLoading) {
        setIsLoading(true);
        getMorePreviews(setIsLoading);
      }

      if (isLastBatch) {
        setEndMessageShown(true);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (conversations.length > 0) {
      conversations.forEach((conversation) => {
        if (!userSocket.socket._callbacks[`$message_${conversation._id}`]) {
          // Messages
          userSocket.socket.on(`message_${conversation._id}`, async (message) => {
            console.log(message);
            await addMessageToConversation(
              conversation._id,
              { _id: message.messageId, text: message.text, author: message.author, createdAt: message.createdAt },
              message.hasImages
            );
          });

          // Status
          userSocket.socket.on(`status_${conversation.recipient.id}`, (status) => {
            changeRecipientStatus(conversation._id, status);
          });

          // Typing...
          userSocket.socket.on(`typing_${conversation._id}_${conversation.recipient.id}`, (typing) => {
            changeTypingStatus(conversation._id, typing);
          });
        }
      });
    }
  }, [conversations]);

  useEffect(() => {
    populateConversations();
  }, [previews, previews?.length]);

  useEffect(() => {
    if (isFirstLoad) {
      if (!isCompany()) {
        getPreviewsAsUser();
        setIsFirstLoad(false);
      } else if (isCompany() && selectedCompany.isNull !== null) {
        getPreviewsAsCompany();
        setIsFirstLoad(false);
      }
    }
  }, [selectedCompany]);

  useEffect(() => {
    const checkScroll = (e) => {
      setScrollTop(e.target?.scrollTop);
      setScrollIsBottom(Math.abs(e.target.scrollHeight - e.target?.scrollTop - e.target?.clientHeight <= 3));
    };

    // FIXME Along with BigServiceOffersList.jsx, ServiceOffersDisplay.jsx, SettingContent.jsx, TendersList.jsx, DashboardHome.jsx & DashboardTender.jsx, scrollHeight is not the right thing when loading (Issue #9)
    setScrollTop(previewsRef.current?.scrollTop);
    setScrollIsBottom(Math.abs(previewsRef.current?.scrollHeight - previewsRef.current?.scrollTop - previewsRef.current?.clientHeight <= 3));

    window.addEventListener("resize", checkScroll);
    previewsRef.current?.addEventListener("scroll", checkScroll);

    return () => {
      window.removeEventListener("resize", checkScroll);
      previewsRef.current?.removeEventListener("scroll", checkScroll);
    };
  }, [scrollTop]);

  useEffect(() => {
    setScrollIsTop(scrollTop === 0);
  }, [scrollTop]);

  useEffect(() => {
    if (selectedId !== null) {
      setMobileConversationSelected(true);
    } else {
      setMobileConversationSelected(false);
    }
  }, [selectedId]);

  return (
    <div>
      <div className="hidden lg:flex space-x-6">
        <div
          ref={previewsRef}
          onScroll={onScroll}
          className={`flex-3/12 3xl:flex-1/5 h-[calc(100svh-5rem)] lg:h-[calc(100svh-3rem)] pr-2 overflow-x-hidden overflow-y-scroll scrollbar-preset-thin ${
            !scrollIsTop && !scrollIsBottom ? "top-bottom-blur-mask" : !scrollIsTop ? "top-blur-mask" : !scrollIsBottom ? "bottom-blur-mask" : ""
          }`}
        >
          <ConversationPreviewList>
            <ConversationPreview
              type="add"
              onClick={() => {
                // TODO Show list of "contacts" (companies who have applied on currently active tenders, etc.)
                showMessage(t("comingSoon"), isCompany() ? t("howToCreateConversationsCompany") : t("howToCreateConversationsClient"));
              }}
            />
            <hr />
            {previews ? (
              previews.length > 0 ? (
                <>
                  {previews.map((preview) => {
                    return <ConversationPreview key={preview._id} preview={preview} selectedId={selectedId} onClick={onPreviewClick} />;
                  })}
                  {!isLastBatch && (
                    <div className={`flex items-center justify-center pt-3 pb-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
                      <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
                    </div>
                  )}
                  {isLastBatch && endMessageShown && (
                    <div className={`flex items-center justify-center py-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
                      <p className="text-xs">{t("reachedConversationsEnd")}</p>
                    </div>
                  )}
                </>
              ) : (
                <div className={`flex items-center justify-center pt-4`}>
                  <p>{t("noConversations")}</p>
                </div>
              )
            ) : (
              <div className={`flex items-center justify-center pt-3`}>
                <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
              </div>
            )}
          </ConversationPreviewList>
        </div>
        <Conversation conversation={getConversationById(selectedId)} fetchMoreMessages={fetchMoreMessages} />
      </div>
      <div className="flex lg:hidden space-x-6">
        {!mobileConversationSelected && (
          <div className={`w-full h-[calc(100svh-5rem)]`}>
            <ConversationPreviewList>
              <ConversationPreview
                type="add"
                onClick={() => {
                  // TODO Show list of "contacts" (companies who have applied on currently active tenders, etc.)
                  showMessage(t("comingSoon"), isCompany() ? t("howToCreateConversationsCompany") : t("howToCreateConversationsClient"));
                }}
              />
              <hr />
              {previews ? (
                previews.length > 0 ? (
                  <>
                    {previews.map((preview) => {
                      return <ConversationPreview key={preview._id} preview={preview} selectedId={selectedId} onClick={onPreviewClick} />;
                    })}
                    {!isLastBatch && (
                      <div className={`flex items-center justify-center pt-3 pb-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
                        <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
                      </div>
                    )}
                    {isLastBatch && endMessageShown && (
                      <div className={`flex items-center justify-center py-4 ${isLoading ? "animate-grow-loading" : "animate-shrink-loading"}`}>
                        <p className="text-xs">{t("reachedConversationsEnd")}</p>
                      </div>
                    )}
                  </>
                ) : (
                  <div className={`flex items-center justify-center pt-4`}>
                    <p>{t("noConversations")}</p>
                  </div>
                )
              ) : (
                <div className={`flex items-center justify-center pt-3`}>
                  <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color={getActualTheme() === "dark" ? "white" : "black"} />
                </div>
              )}
            </ConversationPreviewList>
          </div>
        )}
        {mobileConversationSelected && <Conversation conversation={getConversationById(selectedId)} fetchMoreMessages={fetchMoreMessages} setSelectedId={setSelectedId} />}
      </div>
    </div>
  );
};

export default DashboardMessages;
