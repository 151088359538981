import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import useForm from "../../../hooks/useForm";

import Button from "../../button/Button";
import Steps from "./elements/Steps";

const MultiStepForm = ({ type, nbPages, content, formData, setFormData, action }) => {
  const { t } = useTranslation();

  const { inputByType } = useForm();

  const LAST_PAGE = nbPages - 1;

  const formRef = useRef(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const validateForm = () => {
    let valid = true;

    for (const input of formRef.current.elements) {
      if (input.tagName === "INPUT" || input.tagName === "SELECT" || input.tagName === "TEXTAREA") {
        valid = valid && input.reportValidity();
      }
    }

    return valid;
  };

  const prevPage = (event) => {
    event.preventDefault();

    setCurrentPage(currentPage !== 0 ? currentPage - 1 : 0);
    setSubmitted(false);
  };

  const nextPage = (event) => {
    event.preventDefault();

    if (validateForm()) {
      setCurrentPage(currentPage !== LAST_PAGE ? currentPage + 1 : LAST_PAGE);
    }
  };

  const switchToPage = (page) => {
    if (page <= currentPage) {
      setCurrentPage(page);
    } else {
      if (validateForm()) {
        setCurrentPage(page);
      }
    }
  };

  const submitForm = () => {
    setSubmitted(true);
  };

  return (
    <div className="space-y-4">
      <Steps nbPages={nbPages} currentPage={currentPage} submitted={submitted} switchToPage={switchToPage} />
      <form
        ref={formRef}
        className="form flex flex-col space-y-4"
        onSubmit={(event) => {
          event.preventDefault();

          submitForm();
          action();
        }}
      >
        {content.map((page, i) => {
          const j = i;

          return (
            currentPage === i && (
              <div key={`form-page-${j}`} className="form flex flex-col space-y-4">
                {page.title && <h3 className="text-lg lg:text-xl font-semibold">{page.title}</h3>}
                {Array.isArray(page.content)
                  ? page.content.map((input, i) => {
                      if (input) {
                        return inputByType(formData, setFormData, input, j, i);
                      }

                      return null;
                    })
                  : page.content}
              </div>
            )
          );
        })}
        <div className="flex justify-end space-x-2 ">
          {currentPage !== 0 && <Button type="button" action={prevPage} label={t("back")} modifiers="width-xs" />}
          {currentPage !== LAST_PAGE && <Button action={nextPage} label={t("next")} modifiers="width-xs" />}
          {currentPage === LAST_PAGE && <Button label={type === "sign-up" ? t("signup") : t("submit")} modifiers={`width-xs ${type === "sign-up" ? "arrow" : ""}`} />}
        </div>
      </form>
    </div>
  );
};

export default MultiStepForm;
