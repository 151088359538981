import React from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../common/language/i18n";

import useAuth from "../../hooks/useAuth";
import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";

import image_1_en_light from "../../images/home/1_1-browseTenders_en_light.png";
import image_1_fr_light from "../../images/home/1_2-browseTenders_fr_light.png";
import image_1_en_dark from "../../images/home/1_3-browseTenders_en_dark.png";
import image_1_fr_dark from "../../images/home/1_4-browseTenders_fr_dark.png";

import HomeButton from "../../components/button/HomeButton";
// import Phone from "./Phone";
import Reveal from "./Reveal";

const preloadSrcList = [image_1_en_light, image_1_fr_light, image_1_en_dark, image_1_fr_dark];

const HomeStart = ({ page }) => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { switchToUser, switchToCompany, isLoggedIn, hasActiveSub } = useAuth();
  const { getActualTheme } = useDarkTheme();

  const CLIENT_PAGE = "client",
    BUSINESS_PAGE = "business";

  const getImage = () => {
    const images = [
      [image_1_en_light, image_1_fr_light],
      [image_1_en_dark, image_1_fr_dark]
    ];

    const theme = getActualTheme() === "dark" ? 1 : 0;
    const lang = i18n.language === "en" ? 0 : i18n.language === "fr" ? 1 : null;

    return images[theme][lang];
  };

  return (
    <div className="bg-neutral-100 text-white min-h-screen flex justify-center items-center dark:bg-neutral-850">
      <div className="relative flex flex-col items-center md:max-w-screen-2xl px-4 mx-auto md:flex-row md:space-x-10 sm:px-6 p-8">
        <div className="flex items-center py-5 md:w-full">
          <div className="text-left">
            <Reveal>
              <h2 className="text-3xl leading-10 font-bold tracking-tight text-hoptop-500 sm:text-6xl sm:leading-none">{t("homeSlogan")}</h2>
            </Reveal>
            <p className="max-w-md mx-auto mt-3 text-base text-black sm:text-lg md:mt-5 md:text-xl md:max-w-3xl dark:text-white">
              {t(page === CLIENT_PAGE ? "homeDescription" : page === BUSINESS_PAGE ? "businessDescription" : "")}
            </p>
            <div className="mt-5 space-y-5 sm:w-3/4 sm:mt-8 sm:space-y-3">
              <div className="space-y-2 sm:flex sm:space-x-3 sm:space-y-0">
                <HomeButton
                  link={page === CLIENT_PAGE ? "/businesses" : page === BUSINESS_PAGE && "/"}
                  label={page === CLIENT_PAGE ? t("buttonBusinessZone") : page === BUSINESS_PAGE ? t("buttonClientZone") : ""}
                  modifiers="reverse reverse-animated"
                />
                <HomeButton link={isLoggedIn() ? "/dashboard" : "/login"} label={isLoggedIn() ? t("dashboard") : t("buttonLogin")} />
              </div>
              <HomeButton
                link={
                  isLoggedIn()
                    ? page === CLIENT_PAGE
                      ? "/dashboard/tenders/create"
                      : page === BUSINESS_PAGE
                      ? hasActiveSub()
                        ? "/dashboard/tenders/browse"
                        : "/pricing"
                      : "/login"
                    : "/login"
                }
                action={
                  isLoggedIn()
                    ? page === CLIENT_PAGE
                      ? () => {
                          switchToUser();
                        }
                      : page === BUSINESS_PAGE && hasActiveSub()
                      ? () => {
                          switchToCompany(0);
                        }
                      : null
                    : null
                }
                label={page === CLIENT_PAGE ? t("buttonCreateTender") : page === BUSINESS_PAGE ? t("buttonBrowseTenders") : ""}
                modifiers="height-sm"
              />
            </div>
          </div>
        </div>
        <div className="flex items-center py-5 md:w-full">
          <div className="relative w-full p-3 rounded md:p-0">
            <img src={getImage()} alt="Laptop showing browse tenders" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeStart;
