import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useAuth from "../../hooks/useAuth";
import { AnimatePresence, motion } from "framer-motion";

const SELECTED_STYLES = "text-white dark:text-white font-medium rounded-lg py-3 w-28 relative";
const DESELECTED_STYLES = "font-medium rounded-lg py-3 w-28 text-black dark:text-white transition-colors relative";

export default function SlidePricing() {
  const { t } = useTranslation();

  const { isLoggedIn, hasNoSub, hasPendingSub } = useAuth();

  const [selected, setSelected] = useState("M");

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <section className="w-full text-black px-4 lg:px-8 py-12 lg:py-24 relative overflow-hidden">
      <div className="text-black dark:text-white mb-12 lg:mb-24 relative z-10">
        <motion.h3 className="text-5xl font-bold sm:text-4xl lg:text-5xl text-center mb-5" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          {t("pricingPlans")}
        </motion.h3>
        <motion.p className="text-center mx-auto max-w-2xl mb-5" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}>
          {t("pricingDescription")}
        </motion.p>
        <div className="flex items-center justify-center gap-3 dark:text-white">
          <motion.button onClick={() => setSelected("M")} className={selected === "M" ? SELECTED_STYLES : DESELECTED_STYLES} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <div>{t("monthly")}</div>
            {selected === "M" && <BackgroundShift />}
          </motion.button>
          <motion.button onClick={() => setSelected("A")} className={selected === "A" ? SELECTED_STYLES : DESELECTED_STYLES} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.95 }}>
            <div>{t("annual")}</div>
            {selected === "A" && <BackgroundShift />}
          </motion.button>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row gap-8 lg:gap-4 w-full max-w-6xl mx-auto relative z-10">
        {["hop", "top"].map((plan, index) => (
          // ### PLANS WITH TRIAL TIER (DO NOT TOUCH) ###
          // {["trial", "hop", "top"].map((plan, index) => (
          <motion.div
            key={plan}
            className="w-full bg-white p-6 border-[1px] border-slate-300 rounded-xl"
            variants={cardVariants}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5, delay: index * 0.2 }}
          >
            <p className="text-2xl font-bold mb-2">{t(`${plan}Subscription`)}</p>
            <div className="text-lg mb-6">
              <p className="text-xl font-bold mb-2">{t(`${plan}Description`)}</p>
              <p className="text-xl font-bold mb-2 text-hoptop-500">{t(`${selected === "M" ? "monthly" : "annual"}${plan === "hop" ? "Hop" : "Top"}Special`)}</p>
            </div>
            <div className="overflow-hidden mb-8">
              <AnimatePresence mode="wait">
                {selected === "M" ? (
                  <motion.p
                    key="monthly1"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 50, opacity: 0 }}
                    transition={{ ease: "linear", duration: 0.25 }}
                    className="text-6xl font-bold text-black"
                  >
                    <span>{t(`monthly${plan.charAt(0).toUpperCase() + plan.slice(1)}Price`)}</span>
                    <span className="font-normal text-xl">{t("monthPricing")}</span>
                  </motion.p>
                ) : (
                  <motion.p
                    key="monthly2"
                    initial={{ y: -50, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 50, opacity: 0 }}
                    transition={{ ease: "linear", duration: 0.25 }}
                    className="text-6xl font-bold text-black"
                  >
                    <span>{t(`annual${plan.charAt(0).toUpperCase() + plan.slice(1)}Price`)}</span>
                    <span className="font-normal text-xl">{t("yearPricing")}</span>
                  </motion.p>
                )}
              </AnimatePresence>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                <path d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z" fill="black" />
              </svg>
              <span className="text-base">{t(`${plan}PlanFeature0`)}</span>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                <path d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z" fill="black" />
              </svg>
              <span className="text-base">{t(`${plan}PlanFeature1`)}</span>
            </div>
            <div className="flex items-center gap-2 mb-2">
              <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="shrink-0">
                <path d="M6.35588 11.8345L1.61455 7.17002L0 8.7472L6.35588 15L20 1.57718L18.3968 0L6.35588 11.8345Z" fill="black" />
              </svg>
              <span className="text-base">{t(`${plan}PlanFeature2`)}</span>
            </div>
            <motion.a
              href={
                isLoggedIn()
                  ? hasNoSub() || hasPendingSub()
                    ? `/dashboard/settings/subscription/new/${plan}/${selected === "M" ? "monthly" : "annual"}`
                    : "/dashboard/settings/subscription"
                  : "/login"
              }
              whileHover={{ scale: 1.015 }}
              whileTap={{ scale: 0.985 }}
              className="block w-full mt-10 py-4 font-semibold text-center bg-black text-white rounded-lg uppercase cursor-pointer hover:bg-hoptop-500"
            >
              {t(`${plan}PlanButton`)}
            </motion.a>
          </motion.div>
        ))}
      </div>

      <motion.div className="mt-24 text-center" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ delay: 1 }}>
        <a
          href="/pricing"
          className="inline-block py-4 px-8 text-white font-medium bg-hoptop-500 duration-150 hover:bg-hoptop-600 active:bg-gray-900 rounded-lg shadow-md hover:shadow-none"
        >
          Plus d'information
        </a>
      </motion.div>
    </section>
  );
}

const BackgroundShift = () => <motion.span layoutId="bg-shift" className="absolute inset-0 bg-hoptop-500 dark:bg-hoptop-500 rounded-lg -z-10" />;
