import React from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../hooks/useGeneral";
import usePlaces from "../../hooks/usePlaces";

import Button from "../button/Button";

const SelectedPendingCompany = ({ company, verifyCompany, denyCompany }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getAddressStringFromObject } = usePlaces();

  const userStatuses = ["pending", "verified", "locked", "banned", "deleted"];

  if (company) {
    return (
      <div className="flex flex-col h-full justify-between">
        <div className="flex-1 space-y-2">
          <p>
            {t("neq")}: {company.neq}
          </p>
          <p>
            {t("displayName")}: {company.displayName}
          </p>
          <p>
            {t("storedName")}: {company.storedName}
          </p>
          <p>
            {t("createdAt")}: {formatDate(company.createdAt)}
          </p>
          <p>
            {t("email")}: {company.email}
          </p>
          <p>
            {t("phone")}: {company.phone}
          </p>
          <p>
            {t("range")}: {company.range}
          </p>
          <p>
            {t("status")}: {t(`backend.enums.userStatuses.${userStatuses[company.status]}`)}
          </p>
          <p>
            {t("addr")}: {getAddressStringFromObject(company.addr)}
          </p>
          <div>
            <p>{t("owner")}:</p>
            <div className="ml-6 space-y-2">
              <p>
                {t("name")}: {company.owner.firstName + " " + company.owner.lastName}
              </p>
              <p>
                {t("email")}: {company.owner.email}
              </p>
              <p>
                {t("addr")}: {getAddressStringFromObject(company.owner.addr)}
              </p>
            </div>
          </div>
          <p>
            {t("subdomains")}:{" "}
            {company.subdomains.map((subdomain, i) => {
              return `${t("tenderSubdomains." + subdomain)}${i !== company.subdomains.length - 1 ? ", " : ""}`;
            })}
          </p>
        </div>

        <div className="flex justify-end space-x-4">
          <Button
            action={() => {
              verifyCompany(company._id);
            }}
            label={t("verifyCompany")}
            modifiers="width-xs"
          />
          <Button
            action={() => {
              denyCompany(company._id);
            }}
            label={t("denyCompany")}
            modifiers="width-xs"
          />
        </div>
      </div>
    );
  }

  return <div className="flex items-center justify-center w-full h-full">{t("noCompanySelected")}</div>;
};

export default SelectedPendingCompany;
