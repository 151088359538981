/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "./common/language/i18n";

import "tailwindcss/tailwind.css";

import useAuth from "./hooks/useAuth";
import useDarkTheme from "./hooks/useDarkTheme";
import useLanguage from "./hooks/useLanguage";
import { DimensionsContext } from "./contexts/DimensionsContext";

import AccountAddressSettings from "./pages/dashboard/settings/pages/AccountAddressSettings";
import AccountPasswordSettings from "./pages/dashboard/settings/pages/AccountPasswordSettings";
import Auth from "./pages/auth/Auth";
import BeforeConfirmEmail from "./pages/confirmEmail/BeforeConfirmEmail";
import CompaniesAddressSettings from "./pages/dashboard/settings/pages/CompaniesAddressSettings";
import ConfirmEmail from "./pages/confirmEmail/ConfirmEmail";
import ConfirmSubscription from "./pages/subscribe/ConfirmSubscription";
import Dashboard from "./pages/dashboard/Dashboard";
import DashboardAddCompany from "./pages/dashboard/contents/DashboardAddCompany";
import DashboardAdmin from "./pages/dashboard/contents/DashboardAdmin";
import DashboardCreateTenders from "./pages/dashboard/contents/DashboardCreateTenders";
import DashboardEditTender from "./pages/dashboard/contents/DashboardEditTender";
import DashboardHome from "./pages/dashboard/contents/DashboardHome";
import DashboardMessages from "./pages/dashboard/contents/DashboardMessages";
import DashboardMyProjects from "./pages/dashboard/contents/DashboardMyProjects";
import DashboardMyServiceOffers from "./pages/dashboard/contents/DashboardMyServiceOffers";
import DashboardMyTenders from "./pages/dashboard/contents/DashboardMyTenders";
import DashboardNotifications from "./pages/dashboard/contents/DashboardNotifications";
import DashboardSettings from "./pages/dashboard/settings/DashboardSettings";
// import DashboardStats from "./pages/dashboard/contents/DashboardStats";
import DashboardTender from "./pages/dashboard/contents/DashboardTender";
import DashboardTenders from "./pages/dashboard/contents/DashboardTenders";
// import DashboardKanban from "./pages/dashboard/contents/DashboardKanban";
import ExpiredConfirmEmail from "./pages/confirmEmail/ExpiredConfirmEmail";
import Footer from "./common/footer/Footer";
import Header from "./common/header/Header";
import Home from "./pages/home/Home";
import HomeBusinesses from "./pages/home/HomeBusinesses";
import NotFound from "./pages/notFound/NotFound";
import Policy from "./pages/home/Politique";
import Pricing from "./pages/pricing/Pricing";
import SubscribeHopAnnual from "./pages/subscribe/SubscribeHopAnnual";
import SubscribeHopMonthly from "./pages/subscribe/SubscribeHopMonthly";
import SubscribeTopAnnual from "./pages/subscribe/SubscribeTopAnnual";
import SubscribeTopMonthly from "./pages/subscribe/SubscribeTopMonthly";
import ForgotPassword from "./pages/ForgotResetPassword/ForgotPassword";
import ResetPassword from "./pages/ForgotResetPassword/ResetPassword";
// import SubscribeTrialAnnual from "./pages/subscribe/SubscribeTrialAnnual";
// import SubscribeTrialMonthly from "./pages/subscribe/SubscribeTrialMonthly";
import SubscriptionSettings from "./pages/dashboard/settings/pages/SubscriptionSettings";
// import TempMobileView from "./pages/tempMobileView/TempMobileView";
// import UnderConstruction from "./pages/underConstruction/UnderConstruction";
import UnlockAccount from "./pages/unlockAccount/UnlockAccount";
import LegalNotice from "./pages/legalNotice/LegalNotice";

function App() {
  const { t } = useTranslation();
  document.documentElement.lang = i18n.language;

  const { isLoggedIn, isCompany, isAdmin, hasNoSub, hasPendingSub, hasActiveSub, hasHopSub, hasTopSub, companies } = useAuth();
  const { getActualTheme } = useDarkTheme();
  useLanguage();

  const NO_HEADER_URLS = ["/login", "/sign-up", "/forgot-password", "/reset-password"]; // TODO: Make reset password one work
  const DASHBOARD_URLS = [
    "^/dashboard/?$",
    "^/dashboard/add-company/?$",
    "^/dashboard/admin/?$",
    "^/dashboard/messages/?$",
    "^/dashboard/notifications/?$",
    "^/dashboard/projects/?$",
    "^/dashboard/service-offers/?$",
    "^/dashboard/settings/?$",
    "^/dashboard/settings/account/address/?$",
    "^/dashboard/settings/account/password/?$",
    "^/dashboard/settings/company/address/?$",
    "^/dashboard/settings/subscription/?$",
    // "^/dashboard/settings/subscription/new/trial/annual/?$",
    // "^/dashboard/settings/subscription/new/trial/monthly/?$",
    "^/dashboard/settings/subscription/new/hop/annual/?$",
    "^/dashboard/settings/subscription/new/hop/monthly/?$",
    "^/dashboard/settings/subscription/new/top/annual/?$",
    "^/dashboard/settings/subscription/new/top/monthly/?$",
    "^/dashboard/stats/?$",
    "^/dashboard/tenders/?$",
    "^/dashboard/tenders/[0-9a-f]+/?$",
    "^/dashboard/tenders/[0-9a-f]+/edit/?$",
    "^/dashboard/tenders/browse/?$",
    "^/dashboard/tenders/create/?$",
    "^/dashboard/kanban/?$"
  ];

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  // const [passwordEntered, setPasswordEntered] = useState("");

  // const isPasswordUnlocked = () => {
  //   return (
  //     process.env.REACT_APP_SERVER_ENVIRONMENT === "production" && passwordEntered === process.env.REACT_APP_ADMIN_PASSWORD ||
  //     process.env.REACT_APP_SERVER_ENVIRONMENT === "development"
  //   );
  // };

  const noHeaders = () => {
    let noHeaders = false;

    NO_HEADER_URLS.forEach((item) => {
      if (window.location.pathname.match(item) && !isLoggedIn()) {
        noHeaders = true;
      }
    });

    noHeaders = noHeaders || (isLoggedIn() && isDashboard());

    return noHeaders;
  };

  const isDashboard = () => {
    let isDashboard = false;

    DASHBOARD_URLS.forEach((item) => {
      if (window.location.pathname.match(item)) {
        isDashboard = true;
      }
    });

    return isDashboard;
  };

  // const setPassword = (password) => {
  //   setPasswordEntered(password);

  //   if (password) {
  //     if (password !== process.env.REACT_APP_ADMIN_PASSWORD) {
  //       Swal.fire({
  //         title: t("error"),
  //         text: t("constructionWrongPassword"),
  //         icon: "error",
  //         confirmButtonText: "OK",
  //         customClass: {
  //           confirmButton: "!bg-hoptop-500 !dark:bg-hoptop-600 !shadow-none"
  //         }
  //       });
  //     } else {
  //       localStorage.setItem("adminPassword", password);
  //     }
  //   }
  // };

  useEffect(() => {
    getActualTheme() === "dark" ? import("@sweetalert2/theme-dark/dark.css") : import("@sweetalert2/theme-default/default.css");

    // setPassword(localStorage.getItem("adminPassword"));
  }, []);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    }

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setIsMobile(width < 1024);
  }, [width]);

  const routes = (
    <BrowserRouter>
      <Routes>
        {!isLoggedIn() && (
          <>
            <Route path="/confirm-email" element={<BeforeConfirmEmail />} />
            <Route path="/confirm-email/expired" element={<ExpiredConfirmEmail />} />
            <Route path="/confirm-email/:userId/:confirmationCode" element={<ConfirmEmail />} />

            <Route path="/login" element={<Auth mode={t("login")} />} />
            <Route path="/sign-up" element={<Auth mode={t("signup")} />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password/:userId/:resetCode" element={<ResetPassword />} />
          </>
        )}
        {isLoggedIn() && (
          <>
            <Route path="/confirm-subscription" element={<ConfirmSubscription />} />
            <Route path="/dashboard" element={<DashboardHome />} />
            <Route path="/dashboard/messages" element={<DashboardMessages />} />
            <Route path="/dashboard/notifications" element={<DashboardNotifications />} />
            <Route path="/dashboard/settings" element={<DashboardSettings />} />
            <Route path="/dashboard/settings/account/address" element={<AccountAddressSettings />} />
            <Route path="/dashboard/settings/account/password" element={<AccountPasswordSettings />} />
            <Route path="/dashboard/settings/company/address" element={<CompaniesAddressSettings />} />
            <Route path="/dashboard/settings/subscription" element={<SubscriptionSettings />} />
            <Route path="/dashboard/tenders/:tenderId" element={<DashboardTender />} />
            <Route path="/dashboard/tenders/:tenderId/edit" element={<DashboardEditTender />} />
          </>
        )}
        {isLoggedIn() && !isCompany() && (
          <>
            <Route path="/dashboard/tenders" element={<DashboardMyTenders />} />
            <Route path="/dashboard/tenders/create" element={<DashboardCreateTenders />} />
          </>
        )}
        {isLoggedIn() && isCompany() && (
          <>
            <Route path="/dashboard/projects" element={<DashboardMyProjects />} />
            <Route path="/dashboard/service-offers" element={<DashboardMyServiceOffers />} />
            {/* <Route path="/dashboard/stats" element={<DashboardStats />} /> */}
            <Route path="/dashboard/tenders/browse" element={<DashboardTenders />} />
            {/* <Route path="/dashboard/kanban" element={<DashboardKanban />} /> */}
          </>
        )}
        {isLoggedIn() && isAdmin() && (
          <>
            <Route path="/dashboard/admin" element={<DashboardAdmin />} />
          </>
        )}
        {isLoggedIn() && hasNoSub() && <></>}
        {isLoggedIn() && (hasNoSub() || hasPendingSub()) && (
          <>
            {/* <Route path="/dashboard/settings/subscription/new/trial/annual" element={<SubscribeTrialAnnual />} />
            <Route path="/dashboard/settings/subscription/new/trial/monthly" element={<SubscribeTrialMonthly />} /> */}
            <Route path="/dashboard/settings/subscription/new/hop/annual" element={<SubscribeHopAnnual />} />
            <Route path="/dashboard/settings/subscription/new/hop/monthly" element={<SubscribeHopMonthly />} />
            <Route path="/dashboard/settings/subscription/new/top/annual" element={<SubscribeTopAnnual />} />
            <Route path="/dashboard/settings/subscription/new/top/monthly" element={<SubscribeTopMonthly />} />
          </>
        )}
        {isLoggedIn() && hasPendingSub() && <></>}
        {isLoggedIn() && hasActiveSub() && <></>}
        {isLoggedIn() && hasActiveSub() && (!companies() || (companies() && ((hasHopSub() && companies().length < 1) || (hasTopSub() && companies().length < 3)))) && (
          <>
            <Route path="/dashboard/add-company" element={<DashboardAddCompany />} />
          </>
        )}
        <Route path="/" element={<Home />} />
        <Route path="/businesses" element={<HomeBusinesses />} />
        <Route path="/legal-notice" element={<LegalNotice />} />
        <Route path="/policy" element={<Policy />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/unlock-account/:userId/:confirmationCode" element={<UnlockAccount />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );

  return (
    <DimensionsContext.Provider value={{ width: width, height: height, isMobile: isMobile }}>
      {!noHeaders() ? <Header /> : ""}
      {/* {!noHeaders() && isPasswordUnlocked() ? <Header /> : ""} */}
      <main className="flex-1">
        {isLoggedIn() && isDashboard() ? <Dashboard>{routes}</Dashboard> : routes}
        {/* {isPasswordUnlocked() ? isLoggedIn() && isDashboard() ? <Dashboard>{routes}</Dashboard> : routes : <UnderConstruction setPassword={setPassword} />} */}
      </main>
      {!noHeaders() ? <Footer /> : ""}
      {/* {!noHeaders() && isPasswordUnlocked() ? <Footer /> : ""} */}
    </DimensionsContext.Provider>
  );
}

export default App;
