import React from "react";
import axios from "axios";
import { Trans, useTranslation } from "react-i18next";

import useLogger from "../../hooks/useLogger";

const BeforeConfirmEmail = () => {
  const { t } = useTranslation();

  const { confirmAction, showResponse, askForInput } = useLogger();

  const requestNewEmail = async () => {
    if (localStorage.tempEmail) {
      confirmAction(
        t("confirmEmailConfirmation", { email: localStorage.tempEmail }),
        async () => {
          await axios
            .post(process.env.REACT_APP_BACKEND_URL + `users/resend-confirmation`, { email: localStorage.tempEmail }, { withCredentials: true })
            .then((response) => {
              showResponse(response);
            })
            .catch((e) => {
              showResponse(e);
            });
        },
        changeEmailAddress
      );
    } else {
      changeEmailAddress();
    }
  };

  const changeEmailAddress = async () => {
    const { value: email } = await askForInput(t("changeEmailTitle"), t("changeEmailText"), "email", t("email"));

    if (email) {
      localStorage.setItem("tempEmail", email);

      requestNewEmail();
    }
  };

  return (
    <div className="min-h-screen mx-4 my-24 space-y-6">
      <div className="space-y-2">
        <h2 className="text-3xl font-semibold">{t("emailConfirmation")}</h2>
        <p>{t("confirmationEmailSent")}</p>
        <p>{t("didNotReceive")}</p>
      </div>
      <div className="space-y-2">
        <h3 className="text-2xl font-medium">{t("needANewLink")}</h3>
        <p>
          <Trans i18nKey="requestNewLink">
            You can request a new link{" "}
            <span className="text-neutral-550 dark:text-neutral-200 underline cursor-pointer hover:text-neutral-800 dark:hover:text-white" onClick={requestNewEmail}>
              here
            </span>
            .
          </Trans>
        </p>
      </div>
    </div>
  );
};

export default BeforeConfirmEmail;
