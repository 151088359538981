/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import TendersList from "../../../components/tenders/TendersList";

const DashboardMyProjects = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [projects, setProjects] = useState([]);

  const getProjects = async (date) => {
    let newProjects = [];

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompany.id}/projects${date ? `/${date}` : ""}`, {
        withCredentials: true
      })
      .then((response) => {
        if (!date) {
          setProjects(response.data.projects);
        } else {
          newProjects = response.data.projects;

          setIsLastBatch(response.data.isLastProjects);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newProjects;
  };

  const addNewProjectsToList = async (newProjects) => {
    newProjects.forEach((tender) => {
      projects.push(tender);
    });
  };

  const getMoreProjects = async (setIsLoading) => {
    if (!isLastBatch) {
      const date = projects[projects.length - 1].createdAt;

      const newProjects = await getProjects(date.substring(0, date.length - 1));
      addNewProjectsToList(newProjects);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany.id && isFirstLoad) {
      getProjects();
      setIsFirstLoad(false);
    }
  }, [selectedCompany]);

  return (
    <div className="h-[calc(100svh-5rem)] lg:h-[calc(100svh-3rem)]">
      <Card title={t("dashboardProjects")} className={"max-h-full"} modifiers="no-overflow">
        <TendersList type="myProjects" tenders={projects} getMoreTenders={getMoreProjects} isLastBatch={isLastBatch} />
      </Card>
    </div>
  );
};

export default DashboardMyProjects;
