import React from "react";
import { IconContext } from "react-icons";

import { MdChevronLeft, MdChevronRight } from "react-icons/md";

const CarouselButton = ({ side = "left", length, shownIndex, setShownIndex }) => {
  return (
    <div
      className={`absolute top-1/2 ${
        side === "right" ? "right-0" : "left-0"
      } -translate-y-1/2 w-8 h-8 bg-neutral-350 dark:bg-neutral-500 rounded-full shadow-md cursor-pointer transition-all z-10 hover:bg-neutral-400 hover:dark:bg-neutral-450 hover:scale-105 active:bg-neutral-350 active:dark:bg-neutral-500 active:scale-x-100`}
      onClick={
        side === "right"
          ? () => {
              if (shownIndex !== length - 1) {
                setShownIndex(shownIndex + 1);
              } else {
                setShownIndex(0);
              }
            }
          : () => {
              if (shownIndex !== 0) {
                setShownIndex(shownIndex - 1);
              } else {
                setShownIndex(length - 1);
              }
            }
      }
    >
      <IconContext.Provider value={{ className: "w-full h-full" }}>{side === "right" ? <MdChevronRight /> : <MdChevronLeft />}</IconContext.Provider>
    </div>
  );
};

export default CarouselButton;
