import React, { useState } from "react";
import { DarkModeSwitch } from "react-toggle-dark-mode";

import useDarkTheme from "../../hooks/useDarkTheme";

const ThemeSwitcher = () => {
  const { getActualTheme, setTheme } = useDarkTheme();

  const [test] = useState(getActualTheme()?.slice());
  const nextTheme = getActualTheme() === "light" ? "dark" : "light";

  // In this context, "checked" is equivalent to "dark"
  const [isChecked, setIsChecked] = useState(getActualTheme() === "dark");

  const toggleTheme = () => {
    setTheme(nextTheme);
    setIsChecked(nextTheme === "dark");
  };

  return (
    <DarkModeSwitch
      checked={isChecked}
      onChange={toggleTheme}
      size={56}
      className="w-full h-full"
      sunColor={test === "light" ? "black" : "white"}
      moonColor={test === "light" ? "black" : "white"}
    />
  );
};

export default ThemeSwitcher;
