const useJSHasSaveButton = ({ formData, setFormData }) => {
  const companies = () => {
    return formData.companiesSelectedDropdown;
  };

  const companyNotifications = () => {
    return formData.companyNotificationsSelectedDropdown;
  };

  return { companies, companyNotifications };
};

export default useJSHasSaveButton;
