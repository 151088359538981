/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";

import useAuth from "../../../hooks/useAuth";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { StripeContext } from "../../../contexts/StripeContext";

import SettingContent from "../../../components/settings/SettingContent";
import SettingSidebar from "../../../components/settings/SettingSidebar";
import useLogger from "../../../hooks/useLogger";

const DashboardSettings = ({ categoryId = undefined, tabId = undefined, subpageId = undefined, prevPage }) => {
  const { hasNoSub } = useAuth();
  const { showResponse } = useLogger();
  const settingsContext = useContext(SettingsContext);

  const [mobileSettingSelected, setMobileSettingSelected] = useState(false);

  const [isFirstLoadSettings, setIsFirstLoadSettings] = useState(true);
  const [isFirstLoadStripe, setIsFirstLoadStripe] = useState(true);
  const [subscription, setSubscription] = useState();

  const getSubscription = async () => {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + "subscriptions", {
        withCredentials: true
      })
      .then((response) => {
        setSubscription(response.data);

        setIsFirstLoadStripe(false);
      })
      .catch((e) => {
        showResponse(e);
      });
  };

  useEffect(() => {
    if (!hasNoSub() && isFirstLoadStripe) {
      getSubscription();
    }
  }, [isFirstLoadStripe]);

  useEffect(() => {
    const storageCategoryId = localStorage.getItem("settingsCat"),
      storageTabId = localStorage.getItem("settingsTab"),
      storageSubpageId = localStorage.getItem("settingsSub");

    if (settingsContext && isFirstLoadSettings) {
      if (categoryId && tabId) {
        settingsContext.onTabClick(categoryId, tabId, subpageId);
      } else if (storageCategoryId && storageTabId) {
        settingsContext.onTabClick(storageCategoryId, storageTabId, storageSubpageId);
      }

      setIsFirstLoadSettings(false);
    }
  }, [settingsContext, categoryId, tabId, subpageId, isFirstLoadSettings]);

  useEffect(() => {
    if (settingsContext.selectedCategoryId !== undefined) {
      setMobileSettingSelected(true);
    } else {
      setMobileSettingSelected(false);
    }
  }, [settingsContext?.selectedCategoryId]);

  return (
    <StripeContext.Provider value={{ subscription: subscription, getSubscription: getSubscription }}>
      <div className="hidden lg:flex h-full space-x-6">
        <SettingSidebar fromSpecificPage={categoryId && tabId} />
        <SettingContent
          selectedCategoryId={settingsContext.selectedCategoryId}
          selectedTabId={settingsContext.selectedTabId}
          selectedSubpageId={settingsContext.selectedSubpageId}
          prevPage={prevPage}
        />
      </div>
      <div className="flex lg:hidden h-full">
        {!mobileSettingSelected && <SettingSidebar fromSpecificPage={categoryId && tabId} />}
        {mobileSettingSelected && (
          <SettingContent
            selectedCategoryId={settingsContext.selectedCategoryId}
            selectedTabId={settingsContext.selectedTabId}
            selectedSubpageId={settingsContext.selectedSubpageId}
            prevPage={prevPage}
            mobileSettingSelected={mobileSettingSelected}
          />
        )}
      </div>
    </StripeContext.Provider>
  );
};

export default DashboardSettings;
