import React, { useRef, useState } from "react";

import { TERipple } from "tw-elements-react";

const Checkbox = ({ formData, setFormData, onChangeProp, value, name, from, className, id, required, disabled, label }) => {
  const inputRef = useRef(null),
    rippleRef = useRef(null);

  const checked = from ? formData[from].includes(name) : !onChangeProp ? formData[name] : value;
  const newId = from ? from + "-" + id : id;

  const [hovered, setHovered] = useState(false);

  const onMouseEnter = () => {
    setHovered(true);
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  const onChange = (event) => {
    const value = event.target.checked;
    const groupContents = formData[from];

    if (!from) {
      setFormData({
        ...formData,
        [name]: value
      });
    } else {
      if (!groupContents.includes(name)) {
        setFormData({
          ...formData,
          [from]: [...groupContents, name]
        });
      } else {
        setFormData({
          ...formData,
          [from]: [...groupContents.filter((item) => item !== name)]
        });
      }
    }
  };

  const triggerRipple = () => {
    let opts = { view: window, bubbles: true, cancelable: true, buttons: 1 };
    rippleRef.current.dispatchEvent(new MouseEvent("mousedown", opts));
  };

  return (
    <div className={`flex items-center ${className}`} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <input ref={inputRef} type="checkbox" checked={checked} onChange={!onChangeProp ? onChange : onChangeProp} name={name} id={newId} required={required} className={`hidden`} />
      <TERipple ref={rippleRef} rippleRadius={30} rippleDuration={250} rippleCentered rippleUnbound rippleColor={"#AAAAAA"} className={disabled ? "pointer-events-none" : ""}>
        <div
          className={`w-5 h-5 mb-0.5 rounded-sm cursor-pointer ${
            checked
              ? `bg-hoptop-500 checkbox-mask ${hovered && !disabled ? "bg-hoptop-600 dark:bg-hoptop-400" : ""} ${disabled ? "!bg-hoptop-300 dark:!bg-hoptop-600" : ""}`
              : `border-2 border-neutral-350 ${hovered && !disabled ? "!border-neutral-500 dark:!border-neutral-100" : ""} ${
                  disabled ? "!border-neutral-200 dark:!border-neutral-500" : ""
                }`
          } focus-visible:outline dark:focus-visible:outline-white focus-visible:outline-2 focus-visible:outline-offset-2`}
          onKeyDown={(event) => {
            if (!disabled && event.code === "Space") {
              inputRef.current.click();
              triggerRipple();
            }
          }}
          onClick={() => {
            if (!disabled) {
              inputRef.current.click();
            }
          }}
          tabIndex={0}
        />
      </TERipple>
      {label && (
        <label htmlFor={newId} className={`ml-1.5 cursor-pointer ${disabled ? "!text-neutral-350 dark:!text-neutral-500 pointer-events-none" : ""}`} onClick={triggerRipple}>
          {label}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
