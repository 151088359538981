import React, { useContext, useState } from "react";

import useAuth from "../../../hooks/useAuth";
import useImagePreloader from "../../../hooks/useImagePreloader";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import { MdAddBox, MdOutlineReadMore, MdOutlineQuestionAnswer, MdManageSearch, MdOutlineContentPasteGo, MdHome } from "react-icons/md";
import Logo from "../../../images/logo/logo-dark_theme.svg";

import MobileSidebarButton from "./MobileSidebarButton";

const preloadSrcList = [Logo];

const MobileDashboardSidebar = () => {
  useImagePreloader(preloadSrcList);

  const { fullName, isCompany, profilePic } = useAuth();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  return (
    <div className="fixed bottom-0 w-full h-12 bg-neutral-800 dark:bg-neutral-950 text-white z-20">
      <div className="flex items-center h-full space-x-4">
        <MobileSidebarButton link="/dashboard" icon={<MdHome />} />
        {!isCompany() && (
          <>
            <MobileSidebarButton link="/dashboard/tenders" icon={<MdOutlineReadMore />} />
            <MobileSidebarButton type="center" link="/dashboard/tenders/create" icon={<MdAddBox />} />
          </>
        )}
        {isCompany() && (
          <>
            <MobileSidebarButton link="/dashboard/tenders/browse" icon={<MdManageSearch />} />
            <MobileSidebarButton type="center" link="/dashboard/service-offers" icon={<MdOutlineContentPasteGo />} />
          </>
        )}
        <MobileSidebarButton link="/dashboard/messages" icon={<MdOutlineQuestionAnswer />} />
        <MobileSidebarButton
          type="avatar"
          action={togglePopup}
          image={profilePic()}
          alt={`Profile picture for ${fullName()}`}
          subimage={!selectedCompany.isNull ? selectedCompany.logo : undefined}
          subalt={!selectedCompany.isNull ? `Logo for ${selectedCompany.name}` : undefined}
          popupOpen={popupOpen}
          togglePopup={togglePopup}
        />
      </div>
    </div>
  );
};

export default MobileDashboardSidebar;
