import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import domains from "../data/json/domains.json";

import useGeneral from "../hooks/useGeneral";

import Button from "../components/button/Button";
import Checkbox from "../components/forms/inputs/Checkbox";
import Separator from "../components/Separator";
import SupportedDomain from "../components/domains/SupportedDomain";

const SupportedDomains = ({ startSubscription }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();

  const updateDate = "2024-09-27T23:38:52.107Z";

  const [check, setCheck] = useState(false);

  return (
    <div className="space-y-4">
      <div className="max-h-[26rem] 3xl:max-h-[32.75rem] pr-2 space-y-4 overflow-y-scroll scrollbar-preset">
        <div className="space-y-2">
          <p className={"text-sm text-neutral-500 dark:text-neutral-400"}>{t("lastUpdated", { date: formatDate(updateDate) })}</p>
          <p>{t("supportedDomainsText1")}</p>
        </div>
        <Separator />
        <div className="space-y-2">
          <h3 className="text-xl 3xl:text-2xl font-semibold">{t("supportedDomains")}</h3>
          <div className="space-y-2">
            {domains.map((domain) => {
              return <SupportedDomain key={domain.name} domain={domain.name} subdomains={domain.subdomains} />;
            })}
          </div>
        </div>
        <Separator />
        <div className="space-y-2">
          <p>{t("supportedDomainsText2")}</p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <Checkbox
          id={"check"}
          onChangeProp={(e) => {
            setCheck(e.target.checked);
          }}
          value={check}
          required={true}
          label={t("supportedDomainsCheckbox")}
        />
        <Button
          action={() => {
            startSubscription();
          }}
          label={t("next")}
          disabled={!check}
          modifiers="width-xs no-margin"
        />
      </div>
    </div>
  );
};

export default SupportedDomains;
