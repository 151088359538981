import { useContext } from "react";

import { DimensionsContext } from "../../contexts/DimensionsContext";

const useSettingsJSModifiers = () => {
  const dimensionsContext = useContext(DimensionsContext);

  const userNotificationsServiceOfferGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const userNotificationsConversationGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const userNotificationsMessageGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const companyNotificationsTenderGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const companyNotificationsReviewGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const companyNotificationsConversationGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  const companyNotificationsMessageGroup = () => {
    return dimensionsContext.isMobile ? "notifications notifications-top" : "notifications";
  };

  return {
    userNotificationsServiceOfferGroup,
    userNotificationsConversationGroup,
    userNotificationsMessageGroup,
    companyNotificationsTenderGroup,
    companyNotificationsReviewGroup,
    companyNotificationsConversationGroup,
    companyNotificationsMessageGroup
  };
};

export default useSettingsJSModifiers;
