import React from "react";
import { useTranslation } from "react-i18next";

import TenderCardHome from "../card/dashboard/TenderCardHome";

const TendersListHome = ({ type = "tenders", tenders = [] }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col space-y-4">
      {tenders === null ? (
        <p>{t("fetchingTenders")}</p>
      ) : tenders.length > 0 ? (
        tenders.map((tender) => {
          return (
            <TenderCardHome
              key={tender._id}
              id={tender._id}
              addr={tender.addr}
              createdAt={tender.createdAt}
              deliverBy={tender.deliverBy}
              description={tender.description}
              city={tender.city}
              subdomain={tender.subdomain}
              urgent={tender.urgent}
              status={tender.status}
              title={tender.title}
              user={tender.author}
              loggedUserIsAuthor={tender.loggedUserIsAuthor}
            />
          );
        })
      ) : (
        <p className="text-left">{type === "tenders" ? t("noTenders") : type === "myTenders" ? t("noMyTenders") : type === "myProjects" && t("noMyProjects")}</p>
      )}
    </div>
  );
};

export default TendersListHome;
