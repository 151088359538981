import React from "react";
import { useTranslation } from "react-i18next";

import HeaderLogo from "../../images/logo/logo_header-light_theme.png";
import HeaderLogoDark from "../../images/logo/logo_header-dark_theme.png";

import useAuth from "../../hooks/useAuth";
import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";

import FooterButton from "./FooterButton";

const preloadSrcList = [HeaderLogo, HeaderLogoDark];

const Footer = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { isLoggedIn, logOut } = useAuth();
  const { getActualTheme } = useDarkTheme();

  return (
    <footer className="bg-neutral-300 dark:bg-neutral-850">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/">
              <img className="w-40 h-auto mr-5" src={getActualTheme() === "dark" ? HeaderLogoDark : HeaderLogo} alt="logo" />{" "}
            </a>
            <div className="mt-3">
              <p className="text-gray-700 dark:text-gray-200">
                {t("supportText")}
                <a className="hover:text-hoptop-600 underline" href="mailto:support@hoptop.app">
                  support@hoptop.app
                </a>
              </p>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{t("links")}</h2>
              <ul className="text-gray-700 dark:text-gray-200 font-medium">
                <li className="hover:underline">
                  <FooterButton to="services" text={t("services")} smooth={true} duration={500} />
                </li>
                <li>
                  <FooterButton to="resources" text={t("resources")} smooth={true} duration={500} />
                </li>
                <li>{/* <FooterButton to="team" text={t("team")} smooth={true} duration={500} link="/" /> */}</li>
                <li>
                  <FooterButton to="faq" text="FAQ" smooth={true} duration={500} link="/" />
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{t("webApp")}</h2>
              <ul className="text-gray-700 dark:text-gray-200 font-medium">
                <li>{isLoggedIn() ? <FooterButton link="/dashboard" text={t("dashboard")} /> : <FooterButton link="/login" text={t("dashboard")} />}</li>
                <li>{isLoggedIn() ? <FooterButton action={logOut} text={t("logout")} /> : <FooterButton link="/login" text={t("login")} />}</li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">{t("legal")}</h2>
              <ul className="text-gray-700 dark:text-gray-200 font-medium">
                {/* <li>
                  <a href="/policy" className="hover:underline">
                    {t("privacyPolicy")}
                  </a>
                </li> */}
                <li>
                  <a href="/policy" className="hover:underline">
                    {t("termsConditions")}
                  </a>
                </li>
                <li>
                  <a href="/legal-notice" className="hover:underline">
                    {t("legalNotice")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-neutral-800 sm:mx-auto dark:border-white lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-700 sm:text-center dark:text-gray-200">
            © 2024{" "}
            <a href="https://hoptop.app/" className="hover:underline">
              Hoptop
            </a>
            . {t("allRightsReserved")}
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.facebook.com/hoptop.fb" className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
              <svg className="w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 8 19">
                <path fillRule="evenodd" d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z" clipRule="evenodd" />
              </svg>
              <span className="sr-only">Facebook</span>
            </a>
            <a href="https://www.linkedin.com/company/hoptop-app/" className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ms-5">
              <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M19 0h-14c-2.8 0-5 2.2-5 5v14c0 2.8 2.2 5 5 5h14c2.8 0 5-2.2 5-5v-14c0-2.8-2.2-5-5-5zm-11 19h-3v-11h3v11zm-1.5-12.2c-1 0-1.8-0.8-1.8-1.8s0.8-1.8 1.8-1.8 1.8 0.8 1.8 1.8-0.8 1.8-1.8 1.8zm13.5 12.2h-3v-5.6c0-3.4-4-3.1-4 0v5.6h-3v-11h3v1.5c1.4-2.6 7-2.8 7 2.5v7z" />
              </svg>
              <span className="sr-only">LinkedIn</span>
            </a>

            <a href="https://www.tiktok.com/@hoptop.app" className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ms-5">
              <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 512 512" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M412.19 118.66a109.27 109.27 0 0 1-9.45-5.5 132.87 132.87 0 0 1-24.27-20.62c-18.1-20.71-24.86-41.72-27.35-56.43h.1C349.14 23.9 350 16 350.13 16H267.69V334.78c0 4.28 0 8.51-.18 12.69 0 .52-.05 1-.08 1.56 0 .23 0 .47-.05.71 0 .06 0 .12 0 .18a70 70 0 0 1-35.22 55.56 68.8 68.8 0 0 1-34.11 9c-38.41 0-69.54-31.32-69.54-70s31.13-70 69.54-70a68.9 68.9 0 0 1 21.41 3.39l.1-83.94a153.14 153.14 0 0 0-118 34.52 161.79 161.79 0 0 0-35.3 43.53c-3.48 6-16.61 30.11-18.2 69.24-1 22.21 5.67 45.22 8.85 54.73v.2c2 5.6 9.75 24.71 22.38 40.82A167.53 167.53 0 0 0 115 470.66v-.2l.2.2C155.11 497.78 199.36 496 199.36 496c7.66-.31 33.32 0 62.46-13.81 32.32-15.31 50.72-38.12 50.72-38.12a158.46 158.46 0 0 0 27.64-45.93c7.46-19.61 9.95-43.13 9.95-52.53V176.49c1 .6 14.32 9.41 14.32 9.41s19.19 12.3 49.13 20.31c21.48 5.7 50.42 6.9 50.42 6.9V131.27C453.86 132.37 433.27 129.17 412.19 118.66Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Tiktok</span>
            </a>

            <a href="https://www.instagram.com/hoptop.app/" className="text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white ms-5">
              <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Instagram</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
