import React from "react";

import useGeneral from "../../../hooks/useGeneral";

const DateInfoBox = ({ start, end, className }) => {
  const { formatDate } = useGeneral();

  return (
    <div className={className}>
      <p>
        {formatDate(start)} — {formatDate(end)}
      </p>
    </div>
  );
};

export default DateInfoBox;
