import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import useAuth from "../../hooks/useAuth";
import useDarkTheme from "../../hooks/useDarkTheme";
import useImagePreloader from "../../hooks/useImagePreloader";

import Logo from "../../images/logo/logo-light_theme.svg";
import LogoDark from "../../images/logo/logo-dark_theme.svg";

// eslint-disable-next-line no-unused-vars
import { FiZap, FiBarChart2, FiAlertCircle } from "react-icons/fi";

import Button from "../../components/button/Button";
import FeaturesSection from "../../components/pricing/FeaturesSection";
import SubscriptionTierInfo from "../../components/pricing/SubscriptionTierInfo";

const preloadSrcList = [Logo, LogoDark];

const Pricing = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { isLoggedIn, hasNoSub, hasPendingSub } = useAuth();
  const { getActualTheme } = useDarkTheme();

  const [cycle, setCycle] = useState("monthly");

  const plans = [
    {
      name: t("hopSubscription"),
      price: cycle === "monthly" ? t("monthlyHopPrice") : cycle === "annual" && t("annualHopPrice"),
      description: t("hopDescription"),
      special: cycle === "monthly" ? t("monthlyHopSpecial") : cycle === "annual" && t("annualHopSpecial"),
      button: t("hopPlanButton"),
      link: isLoggedIn()
        ? hasNoSub() || hasPendingSub()
          ? `/dashboard/settings/subscription/new/hop/${cycle === "monthly" ? "monthly" : cycle === "annual" && "annual"}`
          : "/dashboard/settings/subscription"
        : "/login"
    },
    {
      name: t("topSubscription"),
      price: cycle === "monthly" ? t("monthlyTopPrice") : cycle === "annual" && t("annualTopPrice"),
      description: t("topDescription"),
      special: cycle === "monthly" ? t("monthlyTopSpecial") : cycle === "annual" && t("annualTopSpecial"),
      button: t("topPlanButton"),
      link: isLoggedIn()
        ? hasNoSub() || hasPendingSub()
          ? `/dashboard/settings/subscription/new/top/${cycle === "monthly" ? "monthly" : cycle === "annual" && "annual"}`
          : "/dashboard/settings/subscription"
        : "/login"
    }
  ];

  const planFeatures = [
    {
      title: "Fonctionnalités",
      icon: <FiZap />,
      features: [
        { name: "Parcourir les appels d'offres", plans: [true, true] },
        { name: "Faire des offres de services", plans: [true, true] },
        { name: "Clavardage instantané", plans: [true, true] },
        { name: "Limite d'offres de services quotidienne", plans: ["20", "Illimité"] },
        //{ name: "Kanban", plans: [false, true] },
        //{ name: "Phone call request", plans: [false, true] },
        { name: "Inscrivez plusieurs entreprises", plans: [false, "Jusqu'à 3 entreprises"] }
      ]
    },
    /*{
      title: "Analytics",
      icon: <FiBarChart2 />,
      features: [
        { name: "Basic statistics", plans: [true, true] },
        { name: "Advanced statistics", plans: [false, true] }
      ]
    },*/
    {
      title: "Support",
      icon: <FiAlertCircle />,
      features: [
        { name: "Support Hoptop (9:00 à 17:00 UTC -4)", plans: [true, true] }
        //{ name: "VIP support (24/7)", plans: [false, true] }
      ]
    }
  ];

  // ### PLANS WITH TRIAL TIER (DO NOT TOUCH) ###

  // const plans = [
  //   {
  //     name: t("trialSubscription"),
  //     price: cycle === "monthly" ? t("monthlyTrialPrice") : cycle === "annual" && t("annualTrialPrice"),
  //     description: t("trialDescription"),
  //     button: t("trialPlanButton"),
  //     link: isLoggedIn() ? `/dashboard/settings/subscription/new/trial/${cycle === "monthly" ? "monthly" : cycle === "annual" && "annual"}` : "/login"
  //   },
  //   {
  //     name: t("hopSubscription"),
  //     price: cycle === "monthly" ? t("monthlyHopPrice") : cycle === "annual" && t("annualHopPrice"),
  //     description: t("hopDescription"),
  //     button: t("hopPlanButton"),
  //     link: isLoggedIn() ? `/dashboard/settings/subscription/new/hop/${cycle === "monthly" ? "monthly" : cycle === "annual" && "annual"}` : "/login"
  //   },
  //   {
  //     name: t("topSubscription"),
  //     price: cycle === "monthly" ? t("monthlyTopPrice") : cycle === "annual" && t("annualTopPrice"),
  //     description: t("topDescription"),
  //     button: t("topPlanButton"),
  //     link: isLoggedIn() ? `/dashboard/settings/subscription/new/top/${cycle === "monthly" ? "monthly" : cycle === "annual" && "annual"}` : "/login"
  //   }
  // ];

  // const planFeatures = [
  //   {
  //     title: "Features",
  //     icon: <FiZap />,
  //     features: [
  //       { name: "Feature 1", plans: [true, true, true] },
  //       { name: "Feature 2", plans: [false, true, true] },
  //       { name: "Feature 3", plans: [false, false, true] },
  //       { name: "Feature 4", plans: [false, "150 GB", "Unlimited"] }
  //     ]
  //   },
  //   {
  //     title: "Analytics",
  //     icon: <FiBarChart2 />,
  //     features: [
  //       { name: "Analytics 1", plans: [true, true, true] },
  //       { name: "Analytics 2", plans: [false, true, true] },
  //       { name: "Analytics 3", plans: [false, false, true] },
  //       { name: "Analytics 4", plans: ["30", "60", "Custom"] },
  //       { name: "Analytics 5", plans: ["Limited", "Limited", "Unlimited"] },
  //       { name: "Analytics 6", plans: [false, "150 GB", "Unlimited"] }
  //     ]
  //   },
  //   {
  //     title: "Support",
  //     icon: <FiAlertCircle />,
  //     features: [
  //       { name: "Support 1", plans: [true, true, true] },
  //       { name: "Support 2", plans: [false, true, true] },
  //       { name: "Support 3", plans: [false, false, true] },
  //       { name: "Support 4", plans: [false, "150 GB", "Unlimited"] }
  //     ]
  //   }
  // ];

  return (
    <div className="flex flex-col items-center mx-4 my-24 space-y-10">
      <div className="flex flex-col items-center space-y-2">
        <img src={getActualTheme() === "dark" ? LogoDark : Logo} alt="Logo" className="w-1/12" />
        <h2 className="text-3xl font-semibold">{t("pricingPlans")}</h2>
        <p>{t("pricingDescription")}</p>
        <div className="flex lg:w-1/3 space-x-6">
          <Button
            label={t("monthly")}
            action={() => {
              setCycle("monthly");
            }}
            background={cycle === "monthly" ? undefined : ""}
            text={cycle === "monthly" ? undefined : "text-black dark:text-white"}
            modifiers="height-lg no-shadow"
          />
          <Button
            label={t("annual")}
            action={() => {
              setCycle("annual");
            }}
            background={cycle === "annual" ? undefined : ""}
            text={cycle === "annual" ? undefined : "text-black dark:text-white"}
            modifiers="height-lg no-shadow"
          />
        </div>
      </div>
      <div className="lg:w-5/6">
        <table className="w-full">
          <thead>
            <tr className="border-b border-neutral-250 dark:border-neutral-800">
              <th className="w-3/12"></th>
              {plans.map((plan) => {
                return <SubscriptionTierInfo key={plan.name} cycle={cycle} plan={plan} />;
              })}
            </tr>
          </thead>
          <tbody>
            {planFeatures.map((section) => {
              return <FeaturesSection key={section.title} section={section} />;
            })}
          </tbody>
          <tfoot>
            <tr className="border-b border-neutral-250 dark:border-neutral-800">
              <th className="w-3/12"></th>
              {plans.map((plan) => {
                return <SubscriptionTierInfo key={plan.name} cycle={cycle} plan={plan} modifiers="button-only" />;
              })}
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default Pricing;
