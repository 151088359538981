/* eslint-disable jsx-a11y/img-redundant-alt */

import React, { useContext } from "react";

import { InfoOverlayContext } from "../../../contexts/InfoOverlayContext.jsx";

import { MdClose } from "react-icons/md";

import Card from "../../../components/card/Card";
import InfoOverlayIcon from "./elements/InfoOverlayIcon";

const InfoOverlay = ({ title, info }) => {
  const infoOverlay = useContext(InfoOverlayContext);

  return (
    <div className="absolute flex items-center justify-center w-screen h-screen z-40">
      <div onClick={infoOverlay.clearInfo} className="absolute w-full h-full bg-black opacity-75 z-0 animate-fade-in-3/4" />
      <div className="flex flex-col w-5/6 h-11/12 lg:w-4/6 lg:h-5/6 rounded-md z-10 animate-scale-in">
        <Card title={title} modifiers="secondary title-lg">
          <div>
            <div className="absolute top-0 right-0 p-2">
              <InfoOverlayIcon icon={<MdClose />} action={infoOverlay.clearInfo} />
            </div>
            {info}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default InfoOverlay;
