import React from "react";

const CarouselIndicator = ({ length, shownIndex, setShownIndex }) => {
  return (
    <div className="absolute bottom-0 left-1/2 -translate-x-1/2">
      <div className="flex space-x-1.5">
        {[...Array(length)].map((_, i) => {
          return (
            <div
              key={i}
              className={`w-2 h-2 rounded-full cursor-pointer transition-colors duration-300 ${
                i === shownIndex ? "bg-neutral-550 dark:bg-neutral-300" : "bg-neutral-350 dark:bg-neutral-500"
              }`}
              onClick={() => {
                setShownIndex(i);
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default CarouselIndicator;
