import React from "react";
import { useTranslation } from "react-i18next";

const PendingCompany = ({ company, selectCompany, index }) => {
  const { t } = useTranslation();

  return (
    <div
      className="bg-neutral-50 dark:bg-neutral-700 p-3 rounded-lg cursor-pointer hover:brightness-95 hover:dark:brightness-125"
      onClick={() => {
        selectCompany(index);
      }}
    >
      <p className="font-medium">{company.displayName}</p>
      <p>{t("ownerName", { name: company.owner.firstName + " " + company.owner.lastName })}</p>
    </div>
  );
};

export default PendingCompany;
