/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";

import useLogger from "../../../hooks/useLogger";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";

import Card from "../../../components/card/Card";
import BigServiceOffersList from "../../../components/serviceOffers/BigServiceOffersList";

const DashboardMyServiceOffers = () => {
  const { t } = useTranslation();

  const { showResponse } = useLogger();
  const selectedCompany = useContext(SelectedCompanyContext);

  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isLastBatch, setIsLastBatch] = useState(false);
  const [myServiceOffers, setMyServiceOffers] = useState([]);

  const getServiceOffers = async (date) => {
    let newServiceOffers = [];

    await axios
      .get(process.env.REACT_APP_BACKEND_URL + `companies/${selectedCompany.id}/service-offers${date ? `/${date}` : ""}`, { withCredentials: true })
      .then((response) => {
        if (!date) {
          setMyServiceOffers(response.data.serviceOffers);
        } else {
          newServiceOffers = response.data.serviceOffers;

          setIsLastBatch(response.data.isLastServiceOffers);
        }
      })
      .catch((e) => {
        showResponse(e);
      });

    return newServiceOffers;
  };

  const addNewServiceOffersToList = async (newServiceOffers) => {
    newServiceOffers.forEach((serviceOffer) => {
      myServiceOffers.push(serviceOffer);
    });
  };

  const getMoreServiceOffers = async (setIsLoading) => {
    if (!isLastBatch) {
      const date = myServiceOffers[myServiceOffers.length - 1].createdAt;

      const newServiceOffers = await getServiceOffers(date.substring(0, date.length - 1));
      addNewServiceOffersToList(newServiceOffers);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCompany?.isNull === false && isFirstLoad) {
      getServiceOffers();
      setIsFirstLoad(false);
    }
  }, [selectedCompany?.isNull]);

  return (
    <div className="h-[calc(100svh-5rem)] lg:h-[calc(100svh-3rem)]">
      <Card title={t("dashboardMyServiceOffers")} className={"max-h-full"} modifiers="no-overflow">
        <BigServiceOffersList offers={myServiceOffers} getMoreOffers={getMoreServiceOffers} isLastBatch={isLastBatch} />
      </Card>
    </div>
  );
};

export default DashboardMyServiceOffers;
