import React from "react";
import { useTranslation } from "react-i18next";

const TenderInfoBox = ({ type, info, color, modifiers = "" }) => {
  const { t } = useTranslation();

  const COLORS = {
    default: {
      bg: ` ${modifiers.includes("tempTenderCardPriority") && "!bg-neutral-50"} `,
      text: "",
      border: "border-neutral-800 dark:border-neutral-50"
    },
    red: { text: "text-red-600 dark:text-red-300", border: "border-red-900 dark:border-red-400" },
    orange: { text: "text-orange-600 dark:text-orange-400", border: "border-orange-900 dark:border-orange-400" },
    yellow: { text: "text-yellow-500 dark:text-yellow-400", border: "border-yellow-900 dark:border-yellow-400" },
    green: { text: "text-green-700 dark:text-green-400", border: "border-green-900 dark:border-green-400" },
    blue: { text: "text-blue-800 dark:text-blue-400", border: "border-blue-900 dark:border-blue-400" },
    greenWild: { text: "text-green-900 dark:text-green-400", border: "border-green-800 dark:border-green-400" },
    redFort: { text: "text-red-800 dark:text-red-400", border: "border-red-800 dark:border-red-400" }
  };

  if (modifiers.includes("tempTenderCardPriority")) {
    return (
      <div className="flex items-center justify-center p-1">
        <div className="text-center">{info}</div>
      </div>
    );
  }

  return (
    <div className={`flex items-center justify-center rounded-full text-center ${COLORS[color].bg}`}>
      {!modifiers.includes("no-type") && <div className={`font-semibold pr-1.5 border-r ${COLORS[color].text} ${COLORS[color].border}`}>{t(type)}</div>}
      <div className={`font-semibold uppercase ${!modifiers.includes("no-type") && "pl-1.5"} ${modifiers.includes("small-home") && "text-sm 3xl:text-base"} ${COLORS[color].text}`}>
        {info}
      </div>
    </div>
  );
};

export default TenderInfoBox;
