import React from "react";

const DashboardHomeCountdown = ({ value, label }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full space-y-1.5">
      <p className="text-4xl font-mono">{value}</p>
      <p className="text-lg">{label}</p>
    </div>
  );
};

export default DashboardHomeCountdown;
