import { useContext } from "react";

// import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";
import { DimensionsContext } from "../../contexts/DimensionsContext";

const useSettingsSectionJSDirections = () => {
  const dimensionsContext = useContext(DimensionsContext);
  // const settingsFormDataContext = useContext(SettingsFormDataContext);

  const accountAddress = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const accountPassword = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companiesPreferred = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companiesAddress = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companiesDelete = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const themeSwitcher = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const languageSwitcher = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const userNotificationsLogin = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const userNotificationsServiceOffer = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const userNotificationsConversation = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const userNotificationsMessage = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companyNotificationsApproved = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companyNotificationsTender = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companyNotificationsReview = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companyNotificationsConversation = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const companyNotificationsMessage = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const mfaPreferred = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const mfaAuthenticatorButtons = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const mfaSMSButtons = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const mfaEmailButtons = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const mfaCodesButtons = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const loginsRemoveAll = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  const deleteAccountPermanently = () => {
    return dimensionsContext.isMobile ? "vertical" : "horizontal";
  };

  return {
    accountAddress,
    accountPassword,
    companiesPreferred,
    companiesAddress,
    companiesDelete,
    themeSwitcher,
    languageSwitcher,
    userNotificationsLogin,
    userNotificationsServiceOffer,
    userNotificationsConversation,
    userNotificationsMessage,
    companyNotificationsApproved,
    companyNotificationsTender,
    companyNotificationsReview,
    companyNotificationsConversation,
    companyNotificationsMessage,
    mfaPreferred,
    mfaAuthenticatorButtons,
    mfaSMSButtons,
    mfaEmailButtons,
    mfaCodesButtons,
    loginsRemoveAll,
    deleteAccountPermanently
  };
};

export default useSettingsSectionJSDirections;
