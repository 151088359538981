import React from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../hooks/useGeneral";

const TermsAndConditions = () => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();

  const updateDate = "2024-02-29T11:43:02.089Z";

  const sections = [
    {
      title: t("termsConditionsText2"),
      content: <p>{t("termsConditionsText3")}</p>
    },
    {
      title: t("termsConditionsText4"),
      content: <p>{t("termsConditionsText5")}</p>
    },
    {
      title: t("termsConditionsText6"),
      content: (
        <div className="space-y-2">
          <p>{t("termsConditionsText7")}</p>
          <p>{t("termsConditionsText8")}</p>
        </div>
      )
    },
    {
      title: t("termsConditionsText9"),
      content: (
        <div className="space-y-2">
          <p>{t("termsConditionsText10")}</p>
          <p>{t("termsConditionsText11")}</p>
        </div>
      )
    },
    {
      title: t("termsConditionsText12"),
      content: (
        <div className="space-y-2">
          <p>{t("termsConditionsText13")}</p>
          <p>{t("termsConditionsText14")}</p>
        </div>
      )
    },
    {
      title: t("termsConditionsText15"),
      content: <p>{t("termsConditionsText16")}</p>
    }
  ];

  return (
    <div className="max-w-6xl min-h-screen mx-auto mt-28 mb-10 p-5 space-y-10">
      <div className="space-y-4">
        <h2 className="text-3xl font-bold">{t("termsConditions")}</h2>
        <p>{t("lastUpdated", { date: formatDate(updateDate) })}</p>
        <p>{t("termsConditionsText1")}</p>
      </div>
      {sections.map((section, i) => {
        return <Section key={i} title={section.title} content={section.content} index={i + 1} />;
      })}
    </div>
  );
};

const Section = ({ title, content, index }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-2xl font-semibold">
        {index}. {title}
      </h3>
      {content}
    </div>
  );
};

export default TermsAndConditions;
