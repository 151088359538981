/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useMemo, useState } from "react";
import { AdvancedMarker, Map, useMap } from "@vis.gl/react-google-maps";

import Circle from "./components/Circle";

const ShowMap = ({ type = "marker", coords, modifiers = "" }) => {
  const MAP_ID = "8362eff13faf9a41";

  const DEFAULT_ZOOM = 10;
  const LOCATION_ZOOM = 14;

  const DEFAULT_CENTER = useMemo(
    () => ({
      lat: 45.548719,
      lng: -73.725291
    }),
    []
  );

  // const OPTIONS = useMemo(
  //   () => ({
  //     // TODO Setup the proper options
  //   }),
  //   []
  // );

  const map = useMap();

  const [isMapVisible, setIsMapVisible] = useState(true);

  useEffect(() => {
    if (modifiers.includes("hide-map-mobile")) {
      const mediaQuery = window.matchMedia("(max-width: 1024px)");
      const handleMediaQueryChange = (event) => {
        setIsMapVisible(!event.matches);
      };

      handleMediaQueryChange(mediaQuery);

      mediaQuery.addEventListener("change", handleMediaQueryChange);

      return () => {
        mediaQuery.removeEventListener("change", handleMediaQueryChange);
      };
    }
  }, []);

  useEffect(() => {
    if (coords) {
      map?.panTo(coords);
      map?.setZoom(LOCATION_ZOOM);
    }
  }, [coords]);

  return isMapVisible ? (
    <Map
      mapId={MAP_ID}
      className={`flex-1 w-full ${modifiers.includes("small") ? "min-h-72" : "min-h-96"} overflow-hidden rounded-xl`}
      defaultZoom={coords ? LOCATION_ZOOM : DEFAULT_ZOOM}
      defaultCenter={coords ? coords : DEFAULT_CENTER}
    >
      {type === "marker" && <AdvancedMarker position={coords} />}
      {type === "radius" && <Circle position={coords} radius={1200} strokeColor={"#ff0000"} strokeOpacity={0.5} strokeWeight={2} fillColor={"#ff0000"} fillOpacity={0.25} />}
    </Map>
  ) : undefined;
};

export default ShowMap;
