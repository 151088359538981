import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
// import { IconContext } from "react-icons";

import useGeneral from "../../../hooks/useGeneral";
// import useLogger from "../../../hooks/useLogger";
import useTenders from "../../../hooks/useTenders";

// import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
// import { FiMoreVertical } from "react-icons/fi";
// import { GiPin } from "react-icons/gi";

import AvatarInfoBox from "./AvatarInfoBox";
// import EyeToggle from "../../forms/inputs/EyeToggle";
// import Popup from "../../popup/Popup";
// import PopupButton from "../../popup/PopupButton";
import TenderInfoBox from "./TenderInfoBox";
import UrgentTenderIcon from "./UrgentTenderIcon";

const TenderCard = ({ id, user, title, description, city, createdAt, deliverBy, subdomain, urgent, status, loggedUserIsAuthor }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getStatusColor } = useTenders();

  const dropdownRef = useRef(null);
  const dropdownButtonRef = useRef(null);

  // const [isPinned, setIsPinned] = useState(false);
  // const [isFavorited, setIsFavorited] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggleDropdown = (event) => {
  //   event.stopPropagation();
  //   setDropdownOpen(!dropdownOpen);
  // };

  // const togglePin = () => {
  //   setIsPinned(!isPinned);

  //   // TODO Handle pin state update logic here
  // };

  // const toggleFavorite = () => {
  //   setIsFavorited(!isFavorited);

  //   // TODO Handle favorite state update logic here
  // };

  const handleOutsideClick = (event) => {
    if (dropdownOpen && !dropdownRef.current?.contains(event.target) && !dropdownButtonRef.current?.contains(event.target)) {
      setDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <div
      onClick={() => {
        if (document.getSelection().type === "Range") {
          return null;
        }

        window.location.assign(`/dashboard/tenders/${id}`);
      }}
      className="flex flex-col justify-between shadow-md rounded-xl bg-neutral-50 dark:bg-neutral-700 cursor-pointer transition-transform dark:hover:brightness-125 hover:brightness-95 hover:shadow-lg"
    >
      <div className="flex items-center p-4 justify-between">
        <div className="flex-1 min-w-0 mr-6">
          <div>
            <h3 className="w-fit max-w-full text-md lg:text-xl font-semibold text-neutral-700 dark:text-neutral-100">
              <div className="flex items-center space-x-3">
                <p className="whitespace-nowrap overflow-hidden text-ellipsis">{title}</p>
                {urgent ? <UrgentTenderIcon modifiers="small small-card" /> : undefined}
              </div>
            </h3>
          </div>
          <div className="h-5">
            <p className="w-fit max-w-full text-base text-neutral-550 dark:text-neutral-350 whitespace-nowrap overflow-hidden text-ellipsis">{description}</p>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row items-stretch justify-between rounded-b-lg border-t border-b dark:border-neutral-500 border-neutral-250 text-right bg-neutral-100 dark:bg-neutral-600">
        <div className="flex flex-col text-center border-neutral-250 dark:border-neutral-500">
          <div className="flex flex-col justify-center h-full px-4 py-1 text-center">
            <p className="text-neutral-500 dark:text-neutral-300 text-xs">{t("status")}</p>
            <TenderInfoBox type="status" info={t("backend.enums.tenderStatuses." + status)} color={getStatusColor(status)} modifiers="no-type" />
          </div>
        </div>

        <div className="flex flex-col justify-center h-full px-4 py-1 text-center md:border-l border-neutral-250 dark:border-neutral-500">
          <div>
            <p className="text-neutral-500 dark:text-neutral-300 text-xs">{t("name")}</p>
            <AvatarInfoBox avatar={null} name={user} modifiers="text-not-bold hide-avatar-mobile" />
          </div>
        </div>

        <div className="flex flex-col justify-center h-full px-4 py-1 text-center md:border-l border-neutral-250 dark:border-neutral-500">
          <div>
            <p className="text-neutral-500 dark:text-neutral-300 text-xs text-center">{t("city")}</p>
            <div className="text-neutral-700 dark:text-neutral-100">{city}</div>
          </div>
        </div>

        <div className="flex flex-col justify-center h-full px-4 py-1 text-center md:border-l border-neutral-250 dark:border-neutral-500">
          <div>
            <p className="text-neutral-500 dark:text-neutral-300 text-xs text-center">{t("subdomain")}</p>
            <div className="text-neutral-700 dark:text-neutral-100">{t(`tenderSubdomains.${subdomain}`)}</div>
          </div>
        </div>

        <div className="flex flex-col justify-center h-full px-4 py-1 text-center md:border-l md:border-r border-neutral-250 dark:border-neutral-500">
          <div>
            <p className="text-neutral-500 dark:text-neutral-300 text-xs">{t("created")}</p>
            <div className="font-medium text-neutral-700 dark:text-neutral-100">{formatDate(createdAt)}</div>
          </div>
        </div>

        <div className="flex flex-col justify-center h-full px-4 py-1 text-center md:border-l md:border-r border-neutral-250 dark:border-neutral-500">
          <div>
            <p className="text-neutral-500 dark:text-neutral-300 text-xs">{t("deliveryBy")}</p>
            <div className="font-medium text-neutral-700 dark:text-neutral-100">{formatDate(deliverBy)}</div>
          </div>
        </div>

        <div className="flex-1" />

        {/* 
        <div className="flex p-1 md:border-l border-neutral-250 dark:border-neutral-500">
          <EyeToggle />
        </div>
        */}
      </div>
    </div>
  );
};

export default TenderCard;
