/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect } from "react";

import useLogger from "./useLogger";

const useDarkTheme = () => {
  const { showError } = useLogger();

  const [rawTheme, setRawTheme] = useState(localStorage.theme);

  // "Raw theme" means that "sync" will NOT be converted to "light" or "dark"
  const getRawTheme = () => {
    return rawTheme;
  };

  // "Actual theme" means that "sync" will be converted to "light" or "dark"
  const getActualTheme = () => {
    const theme = localStorage.getItem("theme");

    if (theme === "sync") {
      if (window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches) {
        return "dark";
      }

      return "light";
    }

    return theme;
  };

  const setTheme = (theme) => {
    if (theme === "light" || theme === "dark" || theme === "sync") {
      localStorage.setItem("theme", theme);
      setRawTheme(theme);

      setTimeout(() => {
        window.location.reload();
      }, [500]);
    } else {
      showError("invalidTheme");
    }
  };

  useEffect(() => {
    const root = window.document.documentElement;

    switch (getActualTheme()) {
      default:
      case "light":
        root.classList.remove("dark");
        root.classList.add("light");
        break;

      case "dark":
        root.classList.remove("light");
        root.classList.add("dark");
        break;
    }

    // Sets default theme to browser theme (which is usually synched with OS theme)
    if (!getActualTheme()) {
      localStorage.setItem("theme", "sync");
    }
  }, []);

  return { getRawTheme, getActualTheme, setTheme };
};

export default useDarkTheme;
