import React from "react";
import { IconContext } from "react-icons";

import { MdCheck, MdHorizontalRule } from "react-icons/md";

const Feature = ({ section, feature, index }) => {
  return (
    <tr className={`text-sm lg:text-base border-y border-neutral-200 dark:border-neutral-850 ${index === section.features.length - 1 && "border-y-0"}`}>
      <td className="p-4 text-neutral-600 dark:text-neutral-300">{feature.name}</td>
      {feature.plans.map((value, i) => {
        return (
          <td key={`${feature.name}-${i}`}>
            <div className="w-fit mx-auto text-center text-neutral-600 dark:text-neutral-300">
              {typeof value === "boolean" ? (
                <IconContext.Provider value={{ className: "w-5 h-5 text-neutral-600 dark:text-neutral-300" }}>
                  {value === true ? <MdCheck /> : <MdHorizontalRule />}
                </IconContext.Provider>
              ) : (
                value
              )}
            </div>
          </td>
        );
      })}
    </tr>
  );
};

export default Feature;
