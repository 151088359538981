import React from "react";
import { useTranslation } from "react-i18next";

import { AnimatePresence, motion } from "framer-motion";

import Button from "../button/Button";

const SubscriptionTierInfo = ({ cycle, plan, modifiers = "" }) => {
  const { t } = useTranslation();

  return (
    <th className="text-start font-normal p-2 lg:p-4 space-y-2 lg:space-y-4">
      {!modifiers.includes("button-only") && (
        <>
          <p className="text-sm lg:text-base">{plan.name}</p>
          <div className="overflow-hidden">
            <AnimatePresence mode="wait">
              <motion.p
                key={cycle}
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 50, opacity: 0 }}
                transition={{ ease: "linear", duration: 0.25 }}
                className="space-x-1"
              >
                <span className="font-bold text-2xl lg:text-4xl">{plan.price}</span>
                <span>{cycle === "monthly" ? t("monthPricing") : cycle === "annual" && t("yearPricing")}</span>
              </motion.p>
            </AnimatePresence>
          </div>
          <div className="min-h-[7.5rem] lg:min-h-0 space-y-1">
            <p className="text-sm">{plan.description}</p>
            <p className="text-hoptop-500 text-xs lg:text-sm">{plan.special}</p>
          </div>
        </>
      )}
      <Button link={plan.link} label={plan.button} className="text-sm" />
    </th>
  );
};

export default SubscriptionTierInfo;
