import { useContext } from "react";

import useAuth from "../../hooks/useAuth";
import { SettingsFormDataContext } from "../contexts/SettingsFormDataContext";

import domains from "../../data/json/domains.json";

const useJSItems = () => {
  const { companies } = useAuth();
  const settingsFormDataContext = useContext(SettingsFormDataContext);

  const companiesPreferredDropdown = () => {
    if (companies()) {
      return companies().map((company) => {
        return { name: company._id, label: company.displayName };
      });
    }
  };

  const companiesSelectedDropdown = () => {
    if (companies()) {
      return companies().map((company) => {
        return { name: company._id, label: company.displayName };
      });
    }
  };

  const companiesSubdomainsCheckboxes = () => {
    if (companies() && settingsFormDataContext.formData.companiesSubdomainsCheckboxes.length !== 0) {
      const companyDomain = domains.find((domain) => domain.subdomains.find((subdomain) => subdomain.name === settingsFormDataContext.formData.companiesSubdomainsCheckboxes[0]));

      return companyDomain.subdomains.map((subdomain) => {
        return { type: "checkbox", from: "companiesSubdomainsCheckboxes", id: subdomain.name, label: subdomain.label, required: true, disabled: false };
      });
    }

    return [];
  };

  const companyNotificationsSelectedDropdown = () => {
    if (companies()) {
      return companies().map((company) => {
        return { name: company._id, label: company.displayName };
      });
    }
  };

  const mfaPreferredDropdown = () => {
    const items = [];

    // XXX TEMPORARILY HIDDEN
    // if (settingsFormDataContext.formData.mfaAuthenticatorIsSetup) {
    //   items.push({ name: "app", label: "t('app')" });
    // }

    if (settingsFormDataContext.formData.mfaSMSIsSetup) {
      items.push({ name: "text", label: "t('sms')" });
    }

    // XXX TEMPORARILY HIDDEN
    // if (settingsFormDataContext.formData.mfaEmailIsSetup) {
    //   items.push({ name: "email", label: "t('email')" });
    // }

    return items;
  };

  return { companiesPreferredDropdown, companiesSelectedDropdown, companiesSubdomainsCheckboxes, companyNotificationsSelectedDropdown, mfaPreferredDropdown };
};

export default useJSItems;
