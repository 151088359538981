import React from "react";
import { useTranslation } from "react-i18next";

import SupportedSubdomain from "./SupportedSubdomain";

const SupportedDomain = ({ domain, subdomains }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-lg 3xl:text-xl font-medium">{t(`tenderDomains.${domain}`)}</div>
      <div className="space-y-1 ml-4">
        {subdomains.map((subdomain) => {
          return <SupportedSubdomain key={subdomain.name} subdomain={subdomain.name} services={subdomain.services} />;
        })}
      </div>
    </div>
  );
};

export default SupportedDomain;
