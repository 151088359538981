import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import { DimensionsContext } from "../../../contexts/DimensionsContext";

import Popup from "../../popup/Popup";

const MFAStatus = ({ status }) => {
  const dimensionsContext = useContext(DimensionsContext);

  const { t } = useTranslation();

  const [isHovered, setIsHovered] = useState(false);
  const [toggleShown, setToggleShown] = useState(false);

  return (
    <div
      className={`relative ${status ? "bg-green-600 border-green-700" : "bg-red-600 border-red-700"} border-2 w-8 h-8 rounded-full shadow-md ${
        dimensionsContext.isMobile ? "cursor-pointer" : ""
      }`}
      onMouseEnter={
        !dimensionsContext.isMobile
          ? () => {
              setIsHovered(true);
            }
          : undefined
      }
      onMouseLeave={
        !dimensionsContext.isMobile
          ? () => {
              setIsHovered(false);
            }
          : undefined
      }
      onClick={
        dimensionsContext.isMobile
          ? () => {
              setToggleShown(!toggleShown);
            }
          : undefined
      }
    >
      <Popup position="left" width="w-max" height="h-fit" shown={!dimensionsContext.isMobile ? isHovered : toggleShown}>
        <p className="px-2">{t("mfaStatus", { status: status ? t("mfaEnabled") : t("mfaDisabled") })}</p>
      </Popup>
    </div>
  );
};

export default MFAStatus;
