import React from "react";
import { useTranslation } from "react-i18next";

import useImagePreloader from "../../hooks/useImagePreloader";
import useDarkTheme from "../../hooks/useDarkTheme";

import Logo from "../../images/logo/logo-light_theme.svg";
import LogoDark from "../../images/logo/logo-dark_theme.svg";

const preloadSrcList = [Logo, LogoDark];

const NotFound = () => {
  useImagePreloader(preloadSrcList);

  const { t } = useTranslation();

  const { getActualTheme } = useDarkTheme();

  return (
    <div className="flex items-center justify-center min-h-screen px-4 md:px-8">
      <div className="text-center w-full space-y-2">
        <img src={getActualTheme() === "dark" ? LogoDark : Logo} alt="logo" draggable="false" className="w-16 mx-auto mb-4" />
        <h3 className="text-hoptop-500 font-semibold text-4xl md:text-5xl">{t("notFoundTitre")}</h3>
        <p className="text-neutral-800 dark:text-neutral-50 font-semibold text-2xl md:text-3xl">{t("notFound")}</p>
        <p className="text-neutral-600 dark:text-neutral-300 md:text-lg">{t("notFoundDes")}</p>
      </div>
    </div>
  );
};

export default NotFound;
