/* eslint-disable no-loop-func */

import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { IconContext } from "react-icons";

import useAuth from "../../../hooks/useAuth";
import useLogger from "../../../hooks/useLogger";
import { ImageOverlayContext } from "../../../contexts/ImageOverlayContext";
import { SelectedCompanyContext } from "../../../contexts/SelectedCompanyContext";
import { UserSocketContext } from "../../../contexts/UserSocketContext";

import { MdImage, MdSend } from "react-icons/md";

import MessageImagePreview from "./MessageImagePreview";

const MessageInput = ({ conversation }) => {
  const { t } = useTranslation();

  const { userId, isCompany } = useAuth();
  const { showResponse, showMessage } = useLogger();
  const imageOverlay = useContext(ImageOverlayContext);
  const selectedCompany = useContext(SelectedCompanyContext);
  const userSocket = useContext(UserSocketContext);

  const inputRef = useRef(null);
  const imageInputRef = useRef(null);

  const [value, setValue] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const onChange = (event) => {
    event.target.style.height = "";
    event.target.style.height = event.target.scrollHeight + "px";

    if (isTyping) {
      clearTimeout(isTyping);
    } else {
      userSocket.socket.emit("typing", {
        conversationId: conversation._id,
        authorId: isCompany() && !selectedCompany.isNull ? selectedCompany.id : userId(),
        status: true
      });
    }

    setIsTyping(setTimeout(stopTyping, 5000));
    setValue(event.target.value);
  };

  const onImageAdd = (e) => {
    const newImages = [];
    const newImagePreviews = [];

    const files = Array.from(e.target.files);
    files.forEach(async (image) => {
      newImages.push(image);
      newImagePreviews.push(URL.createObjectURL(image));
    });

    if (images.length + newImages.length <= 8) {
      setImages(images.concat(newImages));
      setImagePreviews(imagePreviews.concat(newImagePreviews));
    } else {
      showMessage(t("tooManyImagesTitle"), t("tooManyImagesText", { number: 8 }), "warning");
    }
  };

  const onImageRemove = (index) => {
    const imagesCopy = [...images];
    const imagePreviewsCopy = [...imagePreviews];
    imagesCopy.splice(index, 1);
    imagePreviewsCopy.splice(index, 1);

    setImages(imagesCopy);
    setImagePreviews(imagePreviewsCopy);
  };

  const onImageOpen = (index) => {
    imageOverlay.setImage(imagePreviews[index]);
  };

  const stopTyping = async (event) => {
    setIsTyping(false);
    userSocket.socket.emit("typing", {
      conversationId: conversation._id,
      authorId: isCompany() && !selectedCompany.isNull ? selectedCompany.id : userId(),
      status: false
    });
  };

  const send = async (event) => {
    if ((!event.shiftKey && event.keyCode === 13) || !event.keyCode) {
      event.preventDefault();

      if (value.trim() !== "" || images.length > 0) {
        let attempts = 0;
        let success = false;
        let message = value;
        do {
          const formData = new FormData();

          if (value !== "") {
            formData.append("text", value);
          }

          if (images.length > 0) {
            images.forEach((image) => {
              formData.append("images", image);
            });
          }

          await axios
            .post(process.env.REACT_APP_BACKEND_URL + "conversations/send/" + conversation._id, formData, {
              withCredentials: true,
              headers: { "Content-Type": "multipart/form-data" }
            })
            .then((response) => {
              stopTyping();
              success = true;

              const message = response.data;
              userSocket.socket.emit("messageSent", {
                conversationId: conversation._id,
                messageId: message.id,
                text: value !== "" ? value : undefined,
                hasImages: images.length > 0 ? true : false,
                author: message.author,
                createdAt: message.timestamp
              });
            })
            .catch(async (e) => {
              if (++attempts === 3) {
                setValue(message);
                showResponse(e);
              }
            });

          if (success) {
            setValue("");
            setImages([]);
            setImagePreviews([]);
          }
        } while (success === false && attempts < 3);
      }

      inputRef.current.style.height = "";
      inputRef.current.focus();
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, [conversation]);

  return (
    <div>
      {imagePreviews.length > 0 && (
        <div className="bg-neutral-150 dark:bg-neutral-650 border-b border-b-neutral-250 dark:border-b-neutral-550 px-[0.3125rem] pb-[0.3125rem] rounded-t-lg">
          <div className="flex items-center w-full h-40 px-[0.4375rem] pt-3 pb-[0.3125rem] space-x-4 overflow-x-scroll scrollbar-preset-thin">
            {imagePreviews?.map((image, i) => {
              return <MessageImagePreview key={"image" + i} src={image} alt={"image" + i} index={i} onRemove={onImageRemove} openImage={onImageOpen} />;
            })}
          </div>
        </div>
      )}
      <div className={`flex items-center justify-between bg-neutral-200 dark:bg-neutral-600 w-full min-h-12 max-h-52 ${imagePreviews.length > 0 ? " rounded-b-lg" : "rounded-lg"}`}>
        <div className="h-full">
          <button
            className="h-full p-3 text-neutral-700 dark:text-neutral-300 hover:text-neutral-800 hover:dark:text-neutral-100"
            onClick={() => {
              imageInputRef.current?.click();
            }}
          >
            <IconContext.Provider value={{ className: "w-full h-6" }}>
              <MdImage />
            </IconContext.Provider>
          </button>
          <input ref={imageInputRef} type="file" name="addImages" id="addImages" onChange={onImageAdd} accept="image/*" multiple hidden />
        </div>
        <textarea
          ref={inputRef}
          value={value}
          onChange={onChange}
          onKeyDown={send}
          name="messageInput"
          id="messageInput"
          placeholder={`Message ${conversation.recipient.name}`}
          rows="1"
          className="flex-1 bg-transparent max-h-52 py-3 outline-none resize-none scrollbar-preset-thin"
          autoFocus
        />
        <div className="h-full">
          <button className="h-full p-3 text-neutral-700 dark:text-neutral-300 hover:text-neutral-800 hover:dark:text-neutral-100" onClick={send}>
            <IconContext.Provider value={{ className: "w-full h-6" }}>
              <MdSend />
            </IconContext.Provider>
          </button>
        </div>
      </div>
    </div>
  );
};

export default MessageInput;
