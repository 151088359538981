import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../../hooks/useGeneral";
import usePlaces from "../../../hooks/usePlaces";
import useTenders from "../../../hooks/useTenders";
import { DimensionsContext } from "../../../contexts/DimensionsContext";

import CreateTenderReviewCard from "./CreateTenderReviewCard";
import CreateTenderReviewItem from "./CreateTenderReviewItem";

const CreateTenderReviewPage = ({ data }) => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();
  const { getAddressStringFromObject } = usePlaces();
  const { showQuestion, showQuestionAnswer } = useTenders();
  const dimensionsContext = useContext(DimensionsContext);

  return (
    <div className="space-y-4">
      <div className="hidden lg:block">
        <CreateTenderReviewCard direction="grid">
          <CreateTenderReviewItem label={t("title")} value={data.title} />
          <CreateTenderReviewItem label={t("description")} value={data.description} />
          <CreateTenderReviewItem label={t("address.address")} value={getAddressStringFromObject(data.addr, data.addr.apt !== "", true)} />
        </CreateTenderReviewCard>
      </div>
      <div className="block lg:hidden">
        <CreateTenderReviewCard direction="mobile">
          <CreateTenderReviewItem label={t("title")} value={data.title} />
          <CreateTenderReviewItem label={t("description")} value={data.description} />
          <CreateTenderReviewItem label={t("address.address")} value={getAddressStringFromObject(data.addr, data.addr.apt !== "", true)} />
        </CreateTenderReviewCard>
      </div>
      <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
        <CreateTenderReviewCard direction="mobile">
          <CreateTenderReviewItem label={t("deliverBy")} value={formatDate(data.deliverBy)} />
          <CreateTenderReviewItem label={t("urgent")} value={t(data.urgent ? "yes" : "no")} />
        </CreateTenderReviewCard>
        <CreateTenderReviewCard direction="mobile">
          <CreateTenderReviewItem label={t("domain")} value={data.domain && t("tenderDomains." + data.domain)} />
          <CreateTenderReviewItem label={t("subdomain")} value={data.subdomain && t("tenderSubdomains." + data.subdomain)} />
          <CreateTenderReviewItem label={t("service")} value={data.service && t("tenderServices." + data.service)} />
        </CreateTenderReviewCard>
      </div>
      <CreateTenderReviewCard direction={dimensionsContext.isMobile ? "mobile" : "grid"}>
        {data.questions?.map((question, i) => {
          return <CreateTenderReviewItem key={i} label={showQuestion(data, question.q)} value={showQuestionAnswer(question.a)} />;
        })}
      </CreateTenderReviewCard>
    </div>
  );
};

export default CreateTenderReviewPage;
