import React from "react";
import { motion } from "framer-motion";

import Step from "./Step";

const Steps = ({ nbPages, currentPage, submitted, switchToPage }) => {
  const stepArray = Array.from(Array(nbPages).keys());

  return (
    <div className="flex items-center justify-between gap-1 lg:gap-5">
      {stepArray.map((num) => {
        const stepNum = num + 1;
        const isActive = stepNum <= currentPage;

        return (
          <React.Fragment key={stepNum}>
            <Step num={stepNum} isActive={isActive} submitted={submitted} switchToPage={switchToPage} />
            {stepNum !== stepArray.length && (
              <div className="w-full h-1 rounded-full bg-gray-200 relative">
                <motion.div
                  className="absolute top-0 bottom-0 left-0 bg-hoptop-600 rounded-full"
                  animate={{ width: isActive ? "100%" : 0 }}
                  transition={{ ease: "easeIn", duration: 0.3 }}
                />
              </div>
            )}
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Steps;
