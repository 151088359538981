import React from "react";
import { useTranslation } from "react-i18next";

import useGeneral from "../../hooks/useGeneral";

const LegalNotice = () => {
  const { t } = useTranslation();

  const { formatDate } = useGeneral();

  const updateDate = "2024-09-29T17:06:13.412Z";

  const sections = [
    {
      title: t("cookies"),
      content: (
        <div className="space-y-4">
          <p>{t("cookiesText1")}</p>
          <div className="space-y-2">
            <p>{t("cookiesText2")}</p>
            <p className="pl-4">
              — <span className="font-medium">{t("cookiesText3")}</span> {t("cookiesText4")}
            </p>
          </div>
        </div>
      )
    },
    {
      title: t("disclaimer"),
      content: (
        <div className="space-y-4">
          <p>{t("disclaimerText1")}</p>
          <p>{t("disclaimerText2")}</p>
          <p>{t("disclaimerText3")}</p>
        </div>
      )
    },
    {
      title: t("creditsLicenses"),
      content: (
        <div className="space-y-4">
          <div className="space-y-2">
            <p>{t("creditsLicensesText1")}</p>
            <div className="pl-4 space-y-2">
              <p>
                — <span className="font-medium">{t("creditsLicensesText2")}</span>
              </p>
              <div className="pl-4">
                <p>
                  {t("creditsLicensesText3")}
                  <a href="https://github.com/google/material-design-icons/blob/master/LICENSE" target="_blank" rel="noreferrer" className="text-hoptop-500 hover:underline">
                    {t("creditsLicensesText4")}
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
          <div className="space-y-2">
            <p>
              <span className="font-medium">{t("creditsLicensesText4")}</span>
            </p>
            <p>{t("creditsLicensesText5")}</p>
            <p>{t("creditsLicensesText6")}</p>
          </div>
        </div>
      )
    }
  ];

  return (
    <div className="max-w-6xl min-h-screen mx-auto mt-28 mb-10 p-5 space-y-10">
      <div className="space-y-4">
        <h2 className="text-3xl font-bold">{t("legalNotice")}</h2>
        <p>{t("lastUpdated", { date: formatDate(updateDate) })}</p>
      </div>
      {sections.map((section, i) => {
        return <Section key={i} title={section.title} content={section.content} index={i + 1} />;
      })}
    </div>
  );
};

const Section = ({ title, content, index }) => {
  return (
    <div className="space-y-4">
      <h3 className="text-2xl font-semibold">
        {index}. {title}
      </h3>
      {content}
    </div>
  );
};

export default LegalNotice;
