import i18n from "../common/language/i18n";
import { useTranslation } from "react-i18next";

import useStoredFunctions from "../data/hooks/useStoredFunctions";

const useGeneral = () => {
  const { t } = useTranslation();

  const STORED_FUNCTIONS = useStoredFunctions();

  const formatDate = (value) => {
    if (value) {
      return new Date(value.replace("Z", "")).toLocaleDateString();
    }

    return "";
  };

  const formatCurrency = (value) => {
    const amount = parseInt(value);
    const amountString = amount.toLocaleString(`${i18n.language}-CA`);

    let string = "";

    switch (i18n.language) {
      case "en":
        string = `$${amountString}`;
        break;

      case "fr":
      default:
        string = `${amountString} $`;
        break;
    }

    return string;
  };

  const localeLabelToString = (label) => {
    if (typeof label === "string" && label.includes("t('") && label.includes("')")) {
      return t(label.substring(3, label.length - 2));
    }

    return label;
  };

  const actionStringToFunction = (action) => {
    return STORED_FUNCTIONS[action];
  };

  return { formatDate, formatCurrency, localeLabelToString, actionStringToFunction };
};

export default useGeneral;
