import React, { useState } from "react";
import { IconContext } from "react-icons";
import Popup from "../popup/Popup";

const DashboardHomeButton = ({ icon, label, link }) => {
  const [hover, setHover] = useState(false);

  return (
    <a
      href={link}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      className="flex items-center justify-center h-full rounded-lg cursor-pointer transition-colors hover:bg-neutral-150 hover:dark:bg-neutral-600 active:bg-neutral-250 active:dark:bg-neutral-650"
    >
      <div className="relative w-10 h-10">
        <IconContext.Provider value={{ className: "w-full h-full" }}>{icon}</IconContext.Provider>
        <Popup position="top" width="w-max" height="h-fit" background="bg-neutral-600 dark:bg-neutral-900" shown={hover}>
          <p className="relative p-1 text-neutral-50 text-sm z-10">{label}</p>
        </Popup>
      </div>
    </a>
  );
};

export default DashboardHomeButton;
