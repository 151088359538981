import React from "react";
import { IconContext } from "react-icons";

import { MdAddCircle } from "react-icons/md";

const MessageImagePreview = ({ src, alt, index, onRemove, openImage }) => {
  return (
    <div className="relative flex flex-col bg-neutral-300 dark:bg-neutral-750 h-full p-2 aspect-square rounded-md">
      <button
        className="absolute top-0 right-0 translate-x-1/4 -translate-y-1/4 text-neutral-500 dark:text-neutral-400 hover:text-neutral-700 hover:dark:text-neutral-300"
        onClick={() => {
          onRemove(index);
        }}
      >
        <IconContext.Provider value={{ className: "w-full h-6 rotate-45" }}>
          <MdAddCircle />
        </IconContext.Provider>
      </button>
      <div
        className="flex items-center justify-center bg-neutral-250 dark:bg-neutral-700 w-full h-full rounded-md cursor-pointer"
        onClick={() => {
          openImage(index);
        }}
      >
        <img src={src} alt={alt} className="max-w-full max-h-full rounded-md" />
      </div>
    </div>
  );
};

export default MessageImagePreview;
