import React from "react";
import { Link as ScrollLink } from "react-scroll";

const FooterButton = ({ link, action, text, className, to, smooth, duration }) => {
  const CLASS_LIST = `hover:underline hover:cursor-pointer ${className}`;

  if (window.location.pathname === "/" && to) {
    return (
      <ScrollLink to={to} smooth={smooth} duration={duration}>
        <div className={CLASS_LIST}>{text}</div>
      </ScrollLink>
    );
  }

  if (link) {
    return (
      <a href={link} className={CLASS_LIST}>
        {text}
      </a>
    );
  }

  if (action) {
    return (
      <p
        onClick={(event) => {
          event.preventDefault();
          action();
        }}
        className={`${CLASS_LIST} hover:cursor-pointer`}
      >
        {text}
      </p>
    );
  }

  return <div className={CLASS_LIST}>{text}</div>;
};

export default FooterButton;
