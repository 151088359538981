import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

import useGeneral from "./useGeneral";

import Checkbox from "../components/forms/inputs/Checkbox";
import RadioButton from "../components/forms/inputs/RadioButton";
import DropdownInput from "../components/forms/inputs/DropdownInput";
import InputGroup from "../components/forms/inputs/InputGroup";
import Input from "../components/forms/inputs/Input";
import FormMap from "../components/map/FormMap";

const useForm = () => {
  const { t } = useTranslation();

  const TYPE_CHECKBOX = "checkbox",
    TYPE_RADIO = "radio",
    TYPE_DROPDOWN = "dropdown",
    TYPE_GROUP = "inputgroup",
    TYPE_CAPTCHA = "recaptcha",
    TYPE_ADDRESS = "address",
    TYPE_QUESTION_TEXT = "questionText",
    TYPE_QUESTION_TEXT_AREA = "questionTextArea",
    TYPE_QUESTION_BOOL = "questionBool",
    TYPE_QUESTION_NUM = "questionNum";

  const { localeLabelToString } = useGeneral();

  const inputByType = (formData, setFormData, input, i = 0, j = 0, k = 0) => {
    if (input?.label) {
      input.label = localeLabelToString(input.label);
    }

    switch (input.type) {
      case TYPE_GROUP:
        return (
          <InputGroup key={`input_${input.type}_${j}-${i}-${k}`} name={input.name} label={input.label} direction={input.direction} modifiers={input.modifiers}>
            {input.inputs.map((input, i) => {
              const k = i;

              return inputByType(formData, setFormData, input, i, j, k);
            })}
          </InputGroup>
        );

      case TYPE_CHECKBOX:
        return (
          <Checkbox
            key={`input_${input.type}_${j}-${i}-${k}`}
            formData={formData}
            setFormData={setFormData}
            from={input.from}
            id={input.id}
            name={input.name}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_RADIO:
        return (
          <RadioButton
            key={`input_${input.type}_${j}-${i}-${k}`}
            formData={formData}
            setFormData={setFormData}
            from={input.from}
            id={input.id}
            name={input.name}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_DROPDOWN:
        return (
          <DropdownInput
            key={`input_${input.type}_${j}-${i}-${k}`}
            formData={formData}
            setFormData={setFormData}
            id={input.id}
            name={input.name}
            label={input.label}
            items={input.items}
            hasNone={input.hasNone}
            onChange={input.onChange}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_CAPTCHA:
        return <ReCAPTCHA key={`input_${input.type}_${j}-${i}-${k}`} ref={input.recaptcha} sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY} modifiers={input.modifiers} />;

      case TYPE_ADDRESS:
        return (
          <FormMap
            key={`input_${input.type}_${j}-${i}-${k}`}
            type={input.mapType}
            formData={formData}
            setFormData={setFormData}
            hasHome={input.hasHome}
            modifiers={input.modifiers}
            setShowingMap={input.setShowingMap}
          />
        );

      case TYPE_QUESTION_TEXT:
        return (
          <Input
            key={`input_${input.type}_${j}-${i}-${k}`}
            type="text"
            formData={formData}
            setFormData={setFormData}
            from={input.from}
            index={input.index}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_QUESTION_TEXT_AREA:
        return (
          <Input
            key={`input_${input.type}_${j}-${i}-${k}`}
            type="textarea"
            formData={formData}
            setFormData={setFormData}
            from={input.from}
            index={input.index}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_QUESTION_NUM:
        return (
          <Input
            key={`input_${input.type}_${j}-${i}-${k}`}
            type="num"
            formData={formData}
            setFormData={setFormData}
            from={input.from}
            index={input.index}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            modifiers={input.modifiers}
          />
        );

      case TYPE_QUESTION_BOOL:
        return (
          <InputGroup key={`input_${input.type}_${j}-${i}-${k}`} direction="horizontal" label={input.label}>
            <RadioButton setFormData={setFormData} formData={formData} type="question" from={input.from} index={input.index} label={t("yes")} id="yes" required={input.required} />
            <RadioButton setFormData={setFormData} formData={formData} type="question" from={input.from} index={input.index} label={t("no")} id="no" required={input.required} />
          </InputGroup>
        );

      default:
        return (
          <Input
            key={`input_${input.type}_${j}-${i}-${k}`}
            type={input.type}
            formData={formData}
            setFormData={setFormData}
            id={input.id}
            label={input.label}
            required={input.required}
            disabled={input.disabled}
            hidden={input.hidden}
            modifiers={input.modifiers}
          />
        );
    }
  };

  return { inputByType };
};

export default useForm;
